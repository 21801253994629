.prescriberWrapper {
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    color: #193878;
    border: 1px solid #80a7c6;
    border-radius: 1%;
    min-height: 423px;
    width: 815px;
    margin-top: 7px;
    background: -webkit-gradient(linear, left top, left bottom, from(#daeaf6), to(#80a7c6));
}
.logoAndHeader {
    margin: 23px;
    height: 49px;
    background-repeat: no-repeat;
    background-position: top left;
    background-image: url('../../assets/images/img_bms_logo.png');
}
h1 {
    margin: 20px;
    float: right;
    font-size: 18px;
    font-weight: bold;
    color: #193878;
}
.bannerContent {
    border: 2px solid #5480a2;
    border-radius: 5px;
    background: -webkit-gradient(linear, left top, left bottom, from(#dee9f2), to(#e5f2fd));
    padding: 4px 12px;
    padding-bottom: 17px;
    margin-bottom: 15px;
}
.labelContent {
    margin: 1px 0;
    font-size: 11px;
}
.subHeading {
    font-size: 12px;
    font-weight: bold;
    color: #193878;
    margin: 15px 0;
}
.selectOption {
    font-size: 12px;
    margin-bottom: 15px;
}
.buttonDiv {
    margin-left: 23px;
}

.flex {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.pd5 {
    padding: 5px;
}

.ml {
    margin-left: 10px;
}
