.managePresAcc {
    .headerStyle {
        font-size: 12px;
        font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
        color: #193878;
        font-weight: 600;
        margin: 10px 0;
    }
    .blackBoldText {
        font-size: 12px;
        font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
        color: #000000;
        font-weight: bold;
    }
    .mainContainer {
        background-color: #dee9f2;
        border: 2px solid #5480a2;
        border-radius: 5px;
        padding: 15px 10px;
    }
    .clickLinks {
        color: #2b88b4;
        font-weight: normal;
        cursor: pointer;
    }
    .borderBx {
        border: 2px solid #5480a2;
        margin-top: 10px;
        height: 120px;
        background-color: #FFF;
        overflow-y: scroll;
        padding: 2px;
        width: calc(100% - 4px);
    }
    .whiteBoxCont {
        border: 2px solid #5480a2;
        background-color: #ffffff;
        display: flex;
        align-items: flex-start;
        min-height: 139px;
        padding: 10px 18px;
        flex-direction: column;
    }
    .dFlex{
        display: flex;
    }
    .alignCenter{
        align-items: center;
    }
    .width45p{
        width:45%;
        margin-right: 2%;
    }
    .width49p{
        width:49%;
        margin-right: 2%;
    }
    .width48p{
        width:48%;
        margin-right: 2%;
    }
    .minwidth40p{
        min-width:40%;
    }
    .whiteBoxContNoH {
        border: 2px solid #5480a2;
        background-color: #ffffff;
        display: flex;
        align-items: flex-start;
        padding: 10px 10px;
        flex-direction: column;
    }
    .Bx {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
    .leftBx {
        width: 49%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
    .certifyBox {
        background-color: #edf5fd;
        border: 2px solid #7690ab;
        padding: 5px;
        color: #000000;
        font-size: 12px;
    }
    .contentContainer {
        background-color: #dee9f2;
        border: 2px solid #5480a2;
        background: -webkit-gradient(linear, left top, left bottom, from(#C9E4F8), to(#E5F2FD));
        background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgb(201, 228, 248)), to(rgb(229, 242, 253)));  
        border-radius: 5px;
        border-top-left-radius: 0px;
        padding: 15px 10px;
        font-size: 12px;
        font-family: Verdana, Arial, Helvetica, Clean, sans-serif;
        color: rgb(25, 56, 120);
    }
    .pl5p{
        padding-left: 5%;
    }
    .tabsStyling {
        :global {
            .ant-tabs-tab-active {
                background-color: #cae4f8 !important;
                border-bottom-width: 0.1px !important;
                border-bottom-color: #e3ebf0 !important;
                .ant-tabs-tab-btn {
                    color: #28394e;
                    line-height: 27px;
                    font-size: 11px;
                    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
                    font-weight: normal;
                }
            }
            .ant-tabs-tab {
                background-color: #5480a2;
                margin-right: 3px;
                color: #fff;
                font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
                padding: 1px 9px !important;
                font-size: 11px;
                border-color: #5480a2;
                border-width: 2px;
            }
            .ant-tabs-nav {
                margin: 0 0 0 0 !important;
            }
        }
    }
}
