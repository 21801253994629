$table-border: 2px solid #7690ab;
$cell-border: 1px solid #7690ab;

.headingInfo {
    font-size: 12px;
    padding: 0px 5px;
    margin-top: 0;
    color: #193878;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
}

.scroll {
    max-height: 425px;
    overflow-y: auto;
}

.cursorcls{
    cursor: pointer;
}
//table css
.action_table {
    border: $table-border;
    background-color: #fff;
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    font-size: 11px;
    height: 100%;
    
    .flex_content {
        display: flex;
        justify-content: space-between;
    }
    .grid_content {
        display: grid;
        grid-template-columns: 51% 49%;
        height: auto;
    }
    .empty_data {
        padding: 8px !important;
        text-align: center;
        border-right: 0;
    }
    .wrap_label{
        max-width: 60px;
    }
    th {
        border-right: $table-border;
        border-width: 1px;
        color: #000;
        font-weight: bold;
        padding: 2px 2px 2px 8px;
        font-size: 12px;
        vertical-align: baseline;
        label {
            line-height: 16px;
        }
        .important {
            color: #902828;
            margin-right: 2px;
        }
        &:first-child,&:last-child,&:nth-child(2){
            width: 18%;
        }
        &:nth-child(4) {
            width: 27%;
        }
    }
    .nested_column {
        width: 23%;
        text-align: center;
        padding: 0;
        & > label {
            border-bottom: $cell-border;
            display: block;
        }
        .nested_heading .flex_content {
            padding: 2px 2px 2px 8px;
            flex-basis: 50%;
            &:first-of-type {
                border-right: $cell-border;
                flex-basis: 54%;
            }
        }
    }
    tbody {
        tr:nth-child(odd) {
            background: #f1f5f8;
        }
        .grid_content{
            height: 100%;
        }

        tr:nth-child(even) {
            background: #fff;
        }
        td {
            font-size: 11px;
            padding: 8px;
            border-right: $cell-border;
            &:first-child p {
                margin: 0;
            span{
                text-transform: uppercase;
                font-weight: bold;
                color:#2B88B4;
                margin-right: 4px;
            }
        }
            &:nth-child(3) {
                padding: 0;
                .grid_content label {
                    padding: 8px;
                    flex-basis: 48%;
                    display: flex;
                    align-items: center;
                    &:first-child {
                        border-right: $cell-border;
                    }
                }
            }
            &:nth-child(4){
                p{
                    margin: 0;
                }
            }
        }
    }
    .surveyLink{
        color: #2b88b4;
        cursor: pointer;
        font-weight: bold;
    }
}

.icon {
    vertical-align: super;
    cursor: pointer;
    &:first-child{
        margin-right: 4px;
    }
}

.findPatientPrintBtn {
    :global {
        cursor: pointer;
        color: #2b88b4;
        font-weight: normal;
        text-decoration: none;
        font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
        font-size: 12px;
        position: absolute;
        right: -8px;
        top: -60px;

        b {
            font-size: 16px;
        }
    }
}

//filter css
.actionFilterDiv {
    font-size: 12px;
    color: #193878;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    display: flex;

    .italicText {
        margin: 0px;
        font-style: italic;
        margin-right: 10px;
    }

    select {
        height: 21px;
        margin-inline: 1px;
    }

    input {
        height: 15px;
        width: 162px;
        margin-inline: 2px;
    }

    .btn {
        display: flex;
        margin-left: 5px;
        cursor: pointer;
    }
}
.popupDiv {
    position: absolute;
    top: -40%;
    left: 15%;
}

//checkbox inside dropdown
.checkboxMain {
    position: relative;
    width: 265px;
    margin-inline: 2px;

    .selectDiv {
        width: 100%;
        height: 21px;
        padding-inline: 5px;
        box-sizing: border-box;
        position: relative;
        background-color: #fff;
        border: 1px solid #767676;
        color: #000;
        border-width: 1px;
        cursor: pointer;
        font-weight: 550;
        font-family: Arial, Helvetica, Clean, Sans-Serif;
        font-size: 13px;
        border-radius: 2px;
    }

    .downArwIcon {
        float: right;
        font-size: 10px;
        margin-top: 4px
    }

    .overlayDiv {
        margin-top: 1px;
        width: 100%;
        border: 1px solid #767676;
        font-size: 11px;
        position: absolute;
        background-color: #fff;
        color: #193878;
        border-radius: 2px;

        p {
            padding: 2px 0px;
            margin: 0;
            display: flex;

            &:hover {
                background-color: #3573ee;
                cursor: default;
            }
        }

        .inputCheckbox {
            margin: 2px;
            width: 20px;
            height: 12px;
            cursor: pointer;
        }
    }
}