@mixin iconStyle {
    display: inline-block;
    text-align: center;
    background-repeat: no-repeat;
    background-color: transparent;
    vertical-align: middle;
}

.rems_program_lenalidomide {
    background-image: url(../../assets/images/img_RevAssist_transparent.png);
    @include iconStyle;
    width: 235px;
    height: 41px;
    padding-left: 2px;
}
.rems_program_pomalyst {
    @include iconStyle;
    width: 256px;
    height: 35px;
    padding-left: 2px;
    background-image: url(../../assets/images/Image_PomalidomideREMS_Transparent.png);
}
.rems_program_thalomid {
    @include iconStyle;
    width: 256px;
    height: 35px;
    padding-left: 2px;
    background-image: url(../../assets/images/img_STEPS_transparent.png);
}
.generalRemsProgram {
    @include iconStyle;
    width: 256px;
    height: 41px;
    padding-left: 2px;
    background-position: center;
}
.headerDivider{
    border: 2px solid;
}
.mainContainer {
    background-color: #ffffff;
    width: 763px;
    border-radius: 10px;
    padding: 0 25px;
    padding-bottom: 19px;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    color: #193878;
    box-shadow: 0px 3px 1px rgba(25, 56, 120, 0.3);
}

span.icon {
    @include iconStyle;
    background-image: url(../../assets/images/img_bms_logo.png);
    width: 256px;
    height: 35px;
    padding: 0 0 15px 0px;
}

.headerContainer {
    padding-top: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 5px;
}

.headerDivider_rems_program_lenalidomide {
    border-bottom: 2px solid #fec110;
}
.headerDivider_rems_program_pomalyst {
    border-bottom: 2px solid #007482;
}
.headerDivider_rems_program_thalomid {
    border-bottom: 2px solid #f13945;
}

.contentContainerBtn {
    text-align: center;
    background-color: #f0f0f0;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    div {
        color: #000000;
    }
    .btnWithImage{
        position: relative;
        margin: 12px auto 20px auto;
        .innerImage{
            position: absolute;
            z-index: 9;
            width: 65px;
            background-size: contain !important;
            height: 65px;
            background-repeat: no-repeat !important;
            left: 14px;
            top: 18px;
            &.prodPrescriptionLen{
                background: url(../../assets/images/program/lenalidomide/icon_prescription_blue.png);
            }
            &.prodPrescriptionThal{
                background: url(../../assets/images/program/thalomid/icon_prescription_red.png);
            }
            &.prodPrescriptionPom{
                background: url(../../assets/images/program/pomalyst/icon_prescription_green.png);
            }
        }
        &.smallIcons{
            .innerImage{
                width: 54px;
                height: 54px;
            }
           
        }
        &.noMarP{ 
            p{
                margin-bottom: 0 !important;
            }
        }
    }
}

.ppafIcon {
    box-sizing: border-box;
    width: 400px;
    height: 130px;
    display: inline-block;
    text-align: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;
    vertical-align: middle;
    cursor: pointer;
    border: 0;
}

.custom_btn {
    background-size: cover;
    margin-bottom: 20px;
}

.btn_StandardPrescription_rems_program_lenalidomide {
    background-image: url(../../assets/images/program/lenalidomide/btn_Standard_Prescription_Form.png);
}
.btn_VAPresxription_rems_program_lenalidomide {
    background-image: url(../../assets/images/program/lenalidomide/btn_VA_Prescription_Form.png);
}

.btn_StandardPrescription_rems_program_pomalyst {
    background-image: url(../../assets/images/program/pomalyst/btn_Standard_Prescription_Form.png);
}
.btn_VAPresxription_rems_program_pomalyst {
    background-image: url(../../assets/images/program/pomalyst/btn_VA_Prescription_Form.png);
}

.btn_StandardPrescription_rems_program_thalomid {
    background-image: url(../../assets/images/program/thalomid/btn_Standard_Prescription_Form.png);
}
.btn_VAPresxription_rems_program_thalomid {
    background-image: url(../../assets/images/program/thalomid/btn_VA_Prescription_Form.png);
}

.productHeader {
    color: #666;
    line-height: 18px;
    font-style: italic;
    font-weight: bold;
    padding: 7px 0;
    border-bottom: 1px solid #c0c0c0;
}
