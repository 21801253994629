.calendar {
    width: 660px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
  }
  
  .calendarheader {
    width: 100%;
    height: 50px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .title {
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  
  .title h2 {
    margin: auto;
  }
  
  .tools {
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  
  .tools button {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: #ffffff;
    border: none;
  }
  
  .tools button span:hover {
    color: #99cccc;
  }
  
  .calendarbody {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  
  .tableheader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  
  .weekday {
    width: 78px;
    text-align: center;
    border: 2px solid #AEB3B7;
    position: relative;
    margin: 1px;
    background-color: #5480A2;
    color:#000;
    font-weight: bold;
    height: 31px;
    line-height: 31px;
    font-size: 11px;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    p{
        margin: 0;
    }
  }
  .weekdayWeek {
    height: 31px;
    padding-bottom: 6px;
  }
  
  .tablecontent {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
  }
  
  .calendarday {
    width: 78px;
    height: 70px;
    position: relative;
    border: 2px solid #AEB3B7;
    background-color: #fff;
    margin:1px;
    align-content: center;
    text-align: center;
    cursor: pointer;
  }
  .weekHeader{
    position: relative;
    border: 2px solid #AEB3B7;
    background-color: #fff;
    height: 70px;
    margin: 1px;
    align-content: center;
    width: 35px;
    div{
        transform: rotate(270deg);
        font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
        font-weight: bold;
        width: 60px;
        position: relative;
        right:15px;
    }
  }
  .weektablecontent {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    box-sizing: border-box;
  }
  

  
  .calendarday p {
    position: absolute;
    left: 2px;
    top: -10px;
    padding: 0px 4px;
    border: 2px solid #AEB3B7;
    color: #000;
    font-weight: bold;
    font-size: 12px;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
  }
  .calendarday span {
    position: absolute;
    left: 2px;
    top: 2px;
    padding: 0px;
    border: 2px solid #AEB3B7;
    color: #000;
    font-weight: bold;
    font-size: 12px;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
  }
  .current p {
    color: #000000;
  }
  
  .selected  {
    color: #cc0000;
    font-weight: bold;
    text-decoration: underline;
  }
  .presItems{
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-weight: bold;
  }