$table-border: 2px solid #7690ab;

.content_container {
    background-color: #dee9f2;
    border: 2px solid #5480a2;
    border-radius: 5px;
    padding: 15px 10px;
    .title {
        font-size: 18px;
        font-weight: bold;
        margin-top: 0;
    }
}
.action_btn {
    display: flex;
    justify-content: right;
    margin-bottom: 10px;
    .return_to_dashboard_btn {
        background-image: url(../../assets/images/btn_Return_to_Dashboard.png);
        width: 265px;
        height: 48px;
        margin: 0 3px;
        cursor: pointer;
        border: 0;
        background-color: transparent;
    }
}
.common_table {
    border: $table-border;
    background-color: #fff;
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    font-size: 11px;
    height: 100%;
    th {
        border-right: $table-border;
        border-width: 1px;
        color: #000;
        font-weight: bold;
        padding: 2px 8px;
    }
    tr {
        &:nth-child(odd) {
            background-color: #f1f5f8;
        }
        td {
            border-right: $table-border;
            border-width: 1px;
            color: #4b6075;
            padding: 8px;
            font-size: 11px;
            &:first-child {
                padding: 6px 0px 2px 4px;
            }
        }
    }
    .empty_data {
        padding: 8px !important;
        text-align: center;
    }
}

.sub_title:first-child {
    font-weight: bold;
    margin: 10px 0 6px;
}
.patient_info {
    width: 398px;
    border: $table-border;
    background-color: #fff;
    font-size: 11px;
    color: #4b6075;
    p {
        margin: 0;
        padding: 8px 10px;
        &:nth-child(even) {
            background-color: #f1f5f8;
        }
        span {
            font-weight: bold;
        }
        a {
            color: #2b88b4;
            cursor: pointer;
            text-decoration: underline;
            font-weight: bold;
        }
    }
}

.patient_enroll_info {
    .registered {
        font-size: 8px;
        vertical-align: super;
    }
    .toggle_btn {
        .btn {
            height: 28px;
            background-repeat: no-repeat;
            width: 52px;
            display: inline-block;
            border: 0;
        }
        .hide_enable {
            cursor: pointer;
            background-image: url(../../assets/images/btn_Hide_blue.png);
        }
        .hide_disable {
            background-image: url(../../assets/images/btn_Hide_grey.png);
        }
        .unhide_enable {
            background-image: url(../../assets/images/btn_UnHide_blue.png);
            width: 64px;
            margin-left: 4px;
            cursor: pointer;
        }
        .unhide_disable {
            background-image: url(../../assets/images/btn_UnHide_grey.png);
            width: 64px;
            margin-left: 4px;
        }
    }
    .common_table {
        th {
            &:first-child {
                width: 18%;
            }
            &:nth-child(2) {
                width: 21%;
            }
            &:nth-child(3) {
                width: 20%;
            }
        }
        td {
            &:first-child {
                padding: 6px 0px 6px 4px;
            }
        }
    }
    .pending_enrol_info {
        background-color: #fff;
        border: $table-border;
        width: 246px;
        min-height: 84px;
        padding: 0 5px;
        color: #4b6075;
        font-size: 11px;
        .program_names {
            font-weight: bold;
            color: #2b88b4;
            margin: 0;
            display: flex;
            cursor: pointer;
            &:first-of-type {
                padding-top: 8px;
            }
            .btn_arrow {
                background: url(../../assets/images/btn_arrow_right.png) no-repeat center right;
                height: 7px;
                width: 4px;
                margin: 4px;
            }
        }
    }
}
.auth_info {
    .common_table {
        th {
            &:first-child {
                width: 15%;
            }
            &:nth-child(2) {
                text-align: center;
                padding: 0;
                .cell_title {
                    margin: 0;
                    padding: 2px 0;
                    border-bottom: $table-border;
                    border-width: 1px;
                }
                div {
                    display: grid;
                    grid-template-columns: 30% 40% 30%;
                    p {
                        text-align: justify;
                        margin: 0;
                        padding: 2px 8px;
                        display: flex;
                        align-items: center;
                        height: 100%;
                        &:not(:last-child) {
                            border-right: $table-border;
                            border-width: 1px;
                        }
                    }
                }
            }
            &:nth-child(3) {
                width: 27%;
                .important {
                    color: #902828;
                    margin-right: 2px;
                }
            }
            &:last-child {
                width: 18%;
            }
        }
        td {
            &:nth-child(1) {
                padding-left: 8px;
            }
            &:nth-child(2) {
                text-align: center;
                padding: 0;
                .cell_title {
                    margin: 0;
                    padding: 2px 0;
                    border-bottom: $table-border;
                }
                div {
                    display: grid;
                    grid-template-columns: 30% 40% 30%;
                    height: 100%;
                    p {
                        text-align: left;
                        margin: 0;
                        padding: 2px 8px;
                        display: flex;
                        align-items: center;
                        &:not(:last-child) {
                            border-right: $table-border;
                            border-width: 1px;
                        }
                    }
                }
            }
            button {
                position: relative;
                width: 112px;
                height: 35px;
                border: 0;
                cursor: pointer;
            }
            .activeBtn {
                background: url(../../assets/images/btn_Write_Prescription_small.png) no-repeat center right;
            }
            .disabledBtn {
                background: url(../../assets/images/btn_grey_Write_Prescription_small.png) no-repeat center right;
            }
            .PSRLink {
                color: #2b88b4;
                cursor: pointer;
                font-weight: bold;
            }
            .marginLink {
                margin-top: 2px;
            }
        }
    }
}
.dispense_info {
    .common_table {
        th {
            &:first-child {
                width: 12%;
            }
            &:nth-child(2) {
                width: 7%;
            }
            &:nth-child(3) {
                width: 30%;
            }
            &:last-child {
                width: 22%;
            }
            &:nth-child(4) {
                width: 5%;
            }  
        }
        td {
            padding: 9px  !important;
        }
        a {
            color: #2b88b4;
            cursor: pointer;
            text-decoration: underline;
            font-weight: bold;
        }
    }
}

//modal css
@mixin bold {
    font-weight: bold;
}
@mixin flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 1;
}
.mainmodalContainer {
    @include flex;
    width: 400px;
    color: #193878;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    background: -webkit-gradient(linear, left top, left bottom, from(#c9e4f8), to(#e5f2fd));
    border: 2px solid #5480a2;
    padding: 0;
    top: 115px;
    border-radius: 8px;
    z-index: 2;
}
.whiteInfoContainer {
    width: 100%;
}
.heading {
    text-align: left;
    color: #193878;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    font-weight: bold;
    padding: 10px 10px 5px 10px;
}
.closeBtn {
    position: absolute;
    background-color: #193878;
    color: #ffff;
    border: none;
    border-radius: 3px;
    top: 10px;
    right: 10px;
    cursor: pointer;
    height: 15px;
    width: 15px;
    background: url(../../assets/images/btn_ClosePopup.png) no-repeat center right;
}
.bigPaddingSurvey {
    padding: 0 20px 20px 20px;
        .common_table {
            border: $table-border;
            background-color: #fff;
            width: 100%;
            text-align: left;
            border-collapse: collapse;
            font-size: 11px;
            height: 100%;
            margin-top: 10px;
            th {
                border-right: $table-border;
                color: #000;
                font-weight: bold;
                padding: 8px;
        }
        tr {
                &:nth-child(odd) {
                    background-color: #f1f5f8;
            }
            td {
                    border-right: $table-border;
                    color: #4b6075;
                    padding: 8px;
                    font-size: 11px;
            }
        }
    }

}
.bigPad {
    margin: 10px 20px 20px 20px;
    border: $table-border;
    padding: 5px 10px;
    background-color: white;
    color: #193878;
    .rowTop{
        margin-top: 5px;
    }
    .label {
        text-align: right;
        font-size: 12px;
    }
    .value {
        font-weight: bold;
        margin-left: 5px;
        font-size: 12px;
    }
}
//css hideUnhide popup
.btnContainer {
    @include flex;
    justify-content: center;
    padding: 0 10px 20px 10px;
}
.hideUnhideModal {
    @include flex;
    width: 400px;
    text-align: center;
    color: #193878;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    background: -webkit-gradient(linear, left top, left bottom, from(#c9e4f8), to(#e5f2fd));
    border: 2px solid #5480a2;
    padding: 0;
    top: 115px;
    border-radius: 8px;
    z-index: 1;

    .bigPadding {
        padding: 15px 10px;
        margin-bottom: 20px;
    }
}

.btn {
    position: relative;
    width: 131px;
    height: 39px;
    border: 0;
    padding: 5px;
    cursor: pointer;
}
.continueBtn {
    background: url(../../assets/images/btn_Continue_small_popup.png) no-repeat center right;
}
.cancelBtn {
    left: 5px;
    background: url(../../assets/images/btn_Cancel_small_popup.png) no-repeat center right;
}
/*Popup dispense history*/
.commonBox{
    background-color: #fff;
        width: 100%;
        text-align: left;
        border-collapse: collapse;
        height: 100%;
        margin-top: 10px;
        padding: 3px 0;
        border: 2px solid #7690AB;
        width: 350px;
    margin-left: 4px;
}
.alignRight{
    text-align: right;
    font-size: 12px;
    color:#193878;
    font-weight: 400;
    font-family: Verdana, Arial, Helvetica, Clean, sans-serif;
}
.alignLeft{
    text-align: left;
    font-size: 12px;
    font-weight:bold;
    color:#193878;
    font-family: Verdana, Arial, Helvetica, Clean, sans-serif;
    padding-left: 5px;
}
.dispenseRow{
    padding: 3px 0;
}
