
.reportContainer {
    background-color: #DEE9F2;
    border: 2px solid #5480a2;
    border-radius: 5px;
    padding: 20px 0px;
    font-size: 12px;

    .note {
        padding-right: 5px;
        font-style: italic;
        font-size: 12px;
        text-align: end;
        color: #193878;
        margin-top: -18px;
    }

    .authStatus{
        margin-top: 8px;
        height: 25px;
        margin-left: 10px;
    }
    
    .required {
        color: #7b0305;
    }
    

    .formReport {
        margin: 1%;
    }

    #datepickerTo {
        margin-left: 18px;
    }
    .selectionBox{
        background-color: #fff;
        border: 2px solid #AEB3B7;
        height: 97px;
        width: 250px;
        overflow-x: hidden;
        overflow-y: scroll;
        font-size: 11px;
        margin-left: 18px;
        margin-top: 5px;
        padding: 5px;
    }

    .selectionBoxProgram{
        background-color: #fff;
        border: 2px solid #ccc;
        width: 121px;
        overflow-x: auto;
        font-size: 12px;
        margin-right: 5%;
        margin-left: 15px;
        margin-top: 5px;
        max-height: 83px;
        overflow-y: auto;
    }

    span {
        margin-right: 2px;
    }
    label {
        color: #000;
        font-size: 11px;
    }

    .date1 {
        width: 35%;
        margin-left: 2%;
    }

    .row2 {
        margin-left: '10px';
        margin-top: '1%';
    }

    input[type='checkbox'] {
        margin-top: 6px
    }

    .reportPrintBtn{
        :global {
            cursor: pointer;
            color: #2b88b4;
            font-weight: normal;
            text-decoration: none;
            font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
            font-size: 12px;
            position: absolute;
            right: -8px;
            top: -60px;
            b{
                font-size: 16px;
            }
        }
    }

    .mandatory {
        display: inline-block;
        margin-top: 1%;
        color: #193878;
        font-size: 12px !important;
        font-family: Verdana, Arial, Helvetica, Clean, Sans-S;
        margin-left: 8px;

        &::before {
            content: '* ';
            color: #7b0305;
        }
    }

    .mandatoryDateFrom {
        display: inline-block;
        margin-top: 1%;
        color: #193878;
        font-size: 12px !important;
        font-family: Verdana, Arial, Helvetica, Clean, Sans-S;
        margin-left: 8px;
        margin-right: 20px;
        &::before {
            content: '* ';
            color: #7b0305;
        }
    }

    .nonMandatory{
        display: inline-block;
        margin-top: 1%;
        color: #193878;
        font-size: 12px !important;
        font-family: Verdana, Arial, Helvetica, Clean, Sans-S;
        margin-left: 8px;
    }
}
.mt12{
    margin-top: 12px;
}
.mt15{
    margin-top: 15px;
}


@media print {
    .mt12 {
        border: none;
        margin-top: 30px;
    }
}