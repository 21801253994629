.headerDivider {
    border-bottom: 2px solid #fec110;
    padding: 2px;
    font-size: 8px;
    line-height: 8px;
    clear: both;
}

.headerContainer {
    color: #193878;
    padding-bottom: 8px;
    padding-top: 10px;
}

.contentDivider {
    border-top: 1px solid #C0C0C0;
    padding-top: 15px;
}

.bodyContainer {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    color: #000;
}

.buffer {
    padding: 2px;
    font-size: 8px;
    line-height: 8px;
}

.bodyContent {
    padding: 15px;

    p {
        font-weight: bold;

        a {
            text-decoration: underline;
            font-weight: bold;
            color: #2B88B4;
        }
    }
}

.underline {
    text-decoration: underline;
}

.bold {
    font-weight: bold;
}

.productHeader {
    color: #666;
    line-height: 18px;
    font-style: italic;
    font-weight: bold;
    padding-bottom: 5px;
}

.mainContainer {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    width: 763px;
    border-radius: 10px;
    padding: 0 26px;
    padding-bottom: 19px;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    color: #193878;
    box-shadow: 0px 3px 1px rgba(25, 56, 120, 0.3);
}

@mixin iconStyle {
    display: inline-block;
    text-align: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;
    vertical-align: middle;
}

.actionbutton {
    text-align: center;
    display: flex;
    justify-content: center;
    
}
.actionbutton p {
    font-weight: 100;
}
.actionbutton{
    button{
        margin-right: 10px;
        text-align: left;
        padding: 0 15px;
    }
}


.processPPAFOnlineBtn {
    @include iconStyle;
    width: 265px;
    height: 49px;
    border: none;
    cursor: pointer;
}

.createFaxiblePPAFBtn {
    @include iconStyle;
    width: 265px;
    height: 49px;
    border: none;
    cursor: pointer;
}

.programlogo {
    @include iconStyle;
    width: 250px;
    height: 41px;
    padding: 5px 0px;
    align-self: flex-end;
}

/* styles based on program*/

.rems_program_thalomid {
    .programlogo {
        background-image: url(../../assets/images/img_STEPS_transparent.png);
    }

    .es {
        .processPPAFOnlineBtn {
            background-image: url(../../assets/images/program/thalomid/btn_Process_PPAF_Online_es.png);
            width: 350px;
            height: 110px;
        }

        .createFaxiblePPAFBtn {
            background-image: url(../../assets/images/program/thalomid/btn_Create_a_Faxable_PPAF_es.png);
            width: 350px;
            height: 110px;
        }
    }

    .en {
        .processPPAFOnlineBtn {
            background-image: url(../../assets/images/program/thalomid/btn_Process_PPAF_Online.png);
        }

        .createFaxiblePPAFBtn {
            background-image: url(../../assets/images/program/thalomid/btn_Create_a_Faxable_PPAF.png);
        }
    }

}

.rems_program_lenalidomide {
    .programlogo {
        background-image: url(../../assets/images/img_RevAssist_transparent.png);
    }

    .es {
        .processPPAFOnlineBtn {
            background-image: url(../../assets/images/program/lenalidomide/btn_Process_PPAF_Online_es.png);
            width: 350px;
            height: 110px;
        }

        .createFaxiblePPAFBtn {
            background-image: url(../../assets/images/program/lenalidomide/btn_Create_a_Faxable_PPAF_es.png);
            width: 350px;
            height: 110px;
        }
    }

    .en {
        .processPPAFOnlineBtn {
            background-image: url(../../assets/images/program/lenalidomide/btn_Process_PPAF_Online.png);
        }

        .createFaxiblePPAFBtn {
            background-image: url(../../assets/images/program/lenalidomide/btn_Create_a_Faxable_PPAF.png);
        }
    }
}

.rems_program_pomalyst {
    .programlogo {
        background-image: url(../../assets/images/Image_PomalidomideREMS_Transparent.png);
    }

    .es {
        .processPPAFOnlineBtn {
            background-image: url(../../assets/images/program/pomalyst/btn_Process_PPAF_Online_es.png);
            width: 350px;
            height: 110px;
        }

        .createFaxiblePPAFBtn {
            background-image: url(../../assets/images/program/pomalyst/btn_Create_a_Faxable_PPAF_es.png);
            width: 350px;
            height: 110px;
        }
    }

    .en {
        .processPPAFOnlineBtn {
            background-image: url(../../assets/images/program/pomalyst/btn_Process_PPAF_Online.png);
        }

        .createFaxiblePPAFBtn {
            background-image: url(../../assets/images/program/pomalyst/btn_Create_a_Faxable_PPAF.png);
        }
    }
}
.generalProgramLogo {
    @include iconStyle;
    width: 256px;
    height: 40px;
    padding-left: 2px;
    padding-bottom: 2px;
}
.headerDivider {
    border-bottom: 2px solid;
    padding-top: 5px;
}