.layout {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
html{
    background: #90a5ba url('./../assets/images/img_global_bg.png') repeat-x scroll top left;
    background-repeat: repeat-x;
    background-size: 100% 135%;
    min-height: 100%;
}
@media screen and (max-width:1024px){
    input, select{
        border-color: rgb(118, 118, 118);
        padding: 2px;
        border-width: 1px;
    }
    .layout{
        overflow-x: hidden;
    }
}

@media screen and (min-width:981px) and (max-width:1024px){
    html{
        background-size: 100% 75%;
    }
}
@media screen and (min-width:471px) and (max-width:980px){
    html{
        background-size: 100% 60%;
    }
}
@media screen and (max-width:470px){
    html{
        background-size: 100% 45%;
    }
}
