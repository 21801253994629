.ant-table-wrapper .ant-table-thead >tr>th{
    border-bottom: 2px solid #7690AB;
    text-align: left;
    background: white;
    font-weight: bold;
    font-size: 11px;
    color: rgb(0,0,0);
    font-family:  Verdana, Arial, Helvetica, Clean, Sans-Serif;
}

.ant-table-wrapper table {
    border-radius: 0;
    border: 2px solid #7690AB;

    @media print{
        border: none;
    }
}

.ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
    content: none;
   
}
.ant-table-wrapper .ant-table.ant-table-small .ant-table-thead>tr>th{
    padding: 2px;
    vertical-align: top;
    padding-left: 5px;
}
.ant-select-selector{
    padding: 0px 5px;
    height: 20px;
}

.table-row-dark{
    background-color: #F1F5F8;
}

.table-row-light {
    background-color: #fff;
}

.ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody>tr>td{
    padding: 2px;
    font-size: 11px;
    padding-left: 5px;
}

.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid rgb(118, 118, 118);
    background: #ffffff;
    border-radius: 0;
    height: 21px;
}
.ant-select-item-option-content{
    font-size: 11px;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
}
.ant-select-item-option:hover,.ant-select-item-option:active,.ant-select-item-option:focus,
.ant-select-item-option:focus-within{   
    background-color:#007fff;
    color:white;
}
.ant-select-single {
    font-size: 14px;
    height: 24px;
}
.ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color:#007fff;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
}
.ant-select-single .ant-select-selector .ant-select-selection-search {
    position: absolute;
    top: 0;
    inset-inline-start: 5px;
    inset-inline-end: 5px;
    bottom: 0;
    font-size: 11px;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
}
.ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:last-child {
    border-start-end-radius: 0;
}
.ant-select-dropdown .ant-select-item{
    padding: 5px 5px;
    height: 25px;
    border-radius: 0px;
    min-height:25px;
}

.dispense_details{
    td{
        color: #4B6075;
        font-weight: 700;
        border-right: 1px solid #4B6075;
        text-align: left;
        padding: 5px 8px;
    }
    th{
        padding: 5px 8px;
        border-right: 1px solid #4B6075;
        border-radius: 0;
    }


}

.ant-table-expanded-row, .ant-table-expanded-row-fixed{
  @media print{
        display: table-row !important;

    }
}


.confirmationTable{

    .table-row-dark{
        background-color: #F1F5F8;
        vertical-align: top;
    }
    
    .table-row-light {
        background-color: #fff;
        vertical-align: top;
    }
   @media print{

    .ant-table th,
        .ant-table td{
            border: 0.1px solid rgb(91, 91, 91) !important;
    
        }

 .ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody > tr > td {
            padding: 8px !important;
            font-size: 11px;
        }
 .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child {
            border-start-start-radius: 0 !important;
        }
    
    .ant-table-wrapper table {
        border: none !important;
    }
}       
}


@media print{
.hideCol,.ant-table thead th.hideCol,.ant-table tbody td.hideCol{
    display: none;
}

 .ant-table{
    table-layout: auto !important;
 }
 .ant-table-wrapper .ant-table-expand-icon-col {
    width: 0;
}
.ant-table-wrapper .ant-table-thead >tr>th,.ant-table-wrapper .ant-table.ant-table-small .ant-table-tbody > tr > td{
    font-family: 'Times New Roman';
    color: rgb(0, 0, 0) !important;
    font-size: 16px;
}

.ant-table.ant-table-small .ant-table-tbody > tr > td{
    font-weight: 400;
    font-size: 16px !important;
}


}






