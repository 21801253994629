@mixin alignCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin iconStyle {
    display: inline-block;
    text-align: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;
    vertical-align: middle;
}
@mixin image_commonstyles {
    height: 50px;
    background-repeat: no-repeat;
    background-position: top right;
}
.rems_program_lenalidomide {
 
    background-image: url(../../assets/images/img_RevAssist_transparent.png);
    @include iconStyle;
    width: 235px;
    height: 41px;
    padding-left: 2px;
    position: relative;
    top: 7px;
    
}
.rems_program_pomalyst {
    @include iconStyle;
    width: 256px;
    height: 35px;
    padding-left: 2px;
    background-image: url(../../assets/images/Image_PomalidomideREMS_Transparent.png);
    position: relative;
    top: 7px;
    right: 10px;
}
.rems_program_thalomid {
    @include iconStyle;
    width: 256px;
    height: 35px;
    padding-left: 2px;
    background-image: url(../../assets/images/img_STEPS_transparent.png);
    position: relative;
    top: 7px;
    right: 10px;
}
.headerRemsProgram {
    @include iconStyle;
    width: 256px;
    height: 35px;
    padding-left: 2px;
    position: relative;
    top: 7px;
    right: 10px;
}

.mainContainer {
    background-color: #ffffff;
    
    width: 763px;
    border-radius: 10px;
    padding: 0 26px;
    padding-bottom: 19px;
    padding-top: 9px;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    color: #193878;
    box-shadow: 0px 3px 1px rgba(25, 56, 120, 0.3);
}


.bold {
    font-weight: bold;
}

.header {
    padding-bottom: 8px;
}

span.icon {
    @include iconStyle;
    background-image: url(../../assets/images/img_bms_logo.png);
    width: 256px;
    height: 35px;
    padding: 0 0 15px 0px;
}

.title {
    font-size: 18px;
    font-weight: bold;
    padding: 0 0 10px 0px;
    align-self: flex-start;
}

.headerContainer {
   /*  padding-top: 9px; */
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 10px;
}

.programLogo {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.productButtonLogo {
    padding: 6px 0;
    @include iconStyle;
    background-image: url(../../assets/images/img_RevAssist_container.png);
    width: 156px;
    height: 49px;
    align-self: flex-end;
}
.headerNote{
    color: #000;
}
.note {
    padding: 5px;
    font-style: italic;
    text-align: end;
    padding-top: 10px;
    color: #000;
}
.headerDivider_rems_program_lenalidomide{
    border-bottom: 2px solid #fec110;
}
.headerDivider_rems_program_pomalyst{
    border-bottom: 2px solid #007482;
}
.headerDivider_rems_program_thalomid{
    border-bottom: 2px solid #F13945;
}
.spanishBtn_rems_program_lenalidomide{
    background-image: url(../../assets/images/program/lenalidomide/btn_Spanish.png);
    width: 137px;
    height: 55px;
    background-repeat: no-repeat;
    border: 0;
    background-color: transparent;
}
.spanishBtn_rems_program_pomalyst{
    background-image: url(../../assets/images/program/pomalyst/btn_Spanish.png);
    width: 137px;
    height: 55px;
    background-repeat: no-repeat;
    border: 0;
    background-color: transparent;
}
.spanishBtn_rems_program_thalomid{
    background-image: url(../../assets/images/program/thalomid/btn_Spanish.png);
    width: 137px;
    height: 55px;
    background-repeat: no-repeat;
    border: 0;
    background-color: transparent;
}
.nxtBtn{
    position: relative;
    right: 60px;
    bottom: 10px;
    width: 137px;
    height: 55px;
    background-repeat: no-repeat;
    border: 0;
}
.nxtBtn_rems_program_lenalidomide_en{
    background-image: url(../../assets/images/program/lenalidomide/btn_Next.png);  
}
.nxtBtn_rems_program_lenalidomide_es{
    background-image: url(../../assets/images/program/lenalidomide/btn_Next_spanish.png);   
}
.nxtBtn_rems_program_pomalyst_en{
    background-image: url(../../assets/images/program/pomalyst/btn_Next.png);
}
.nxtBtn_rems_program_pomalyst_es{
    background-image: url(../../assets/images/program/pomalyst/btn_Next_spanish.png);
}
.nxtBtn_rems_program_thalomid_en{
    background-image: url(../../assets/images/program/thalomid/btn_Next.png);
}
.nxtBtn_rems_program_thalomid_es{
    background-image: url(../../assets/images/program/thalomid/btn_Next_spanish.png);
}
.required {
    color: #7b0305;
}

.contentContainer {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    margin-bottom: 12px;
    padding: 10px 20px 0 70px;
    div{
        color:#000000;
    }
}

.formHeader {
    padding: 15px;
    padding-bottom: 5px;
    font-weight: bold;
}

.formContainer {
    display: grid;
    grid-template-columns: 0.8fr 1.3fr;
    place-items: start;

    div:nth-child(odd) {
        place-self: flex-end;
    }
}

.inputContainer {
    padding: 5px;
}

.formSubHeader {
    padding: 5px 15px 15px 15px;
}

.productHeader{
    color: #666;
    line-height: 18px;
    font-style: italic;
    font-weight: bold;
    padding: 7px 0;
    border-bottom: 1px solid #C0C0C0;
}
.extInput{
    position: relative;
    bottom: 49px;
    left: 215px;
}
