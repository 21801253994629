.acccountConfirmation{
@mixin headerInfo {
    font-weight: bold;
    font-style: italic;
    padding: 0 15px;
    
}
.formHeader {
    display: flex;
    //align-items: flex-start;
    padding: 20px;
    padding-right: 40px;
}
.digital_wrapper {
    position: relative;
    min-height: 200px;
}
.checkbox_wrapper{
    display: grid;
    grid-template-columns: 0.7fr 1.3fr;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    color: #193878;
}
.checkbox_content{
    text-align: right;
    margin: 5px;
}
.digital_content {
    position: absolute;
    left: -2%;
}
.digital_info{
    padding: 0 15px;
}
.digital_term{
    margin: 5px;
    max-width: 470px;
    word-wrap: break-word;
}
.heading_info {
    // @include headerInfo();
    display: flex;
    //column-gap: 5px;
     font-weight: bold;
     font-style: italic;
    // padding: 0 0px 0 15px;
    width: 565px;
   
}

.digital_sign {
    @include headerInfo();
    font-style: normal;
}
.contentContainer {
    background-color: #dee9f2;
    border: 2px solid #5480a2;
    border-radius: 5px;
    margin-bottom: 12px;
    padding-bottom: 12px;
}

.firstContainer {
    padding: 10px;
    border-bottom: 1px solid #c0c0c0;
}

.secondContainer {
    // padding: 10px;
    // padding-top: 20px;
    display: none;
}

.subHeader {
   /*  padding: 15px; */
   padding: 5px 0;
   padding-bottom: 15px;
    // font-weight: bold;
}

.bold {
    font-weight: bold;
}

.flex {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.pd5 {
    padding: 5px;
}

.slimPad_T {
    padding-top: 5px;
}

.ml {
    margin-left: 10px;
}
.note {
    padding: 5px;
    font-style: italic;
    text-align: end;
    padding-top: 19px;
}
.required {
    color: #7b0305;
}

.tblcont{ 
        border-radius: 1px; 
        margin-bottom: 12px;
        padding-top: 15px;
        padding-left: 150px;      
        justify-content: center;
        text-align: center;
        display:none;
}

td{
    border: 1px solid #090d10;
    padding: 7px 80px 7px 100px;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
}

@media print {
    .tblcont {
      display: block;
    }
    .tableLabel{
     padding: 7px 5px 7px 100px;
     text-align: right;
    }
    .tableValue{
     padding: 7px 80px 7px 5px;
     text-align: left;
    }
  }

.headerprint {
    padding-top: 25px;
    padding-left: 40px;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-weight: bold;
    display:none;
}

.tableprint {
    border-collapse: collapse;
}

@media print {
    .headerprint { 
        padding-left: 150px;
        display: block;
        font-family: BMS-Humanity;
    }
  }
}