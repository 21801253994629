.container {
    font-size: 12px;
    color: #476392;
    width: 100%;
    text-align: center;
    margin-top: 2%;
    margin-bottom: 2%;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    @media print{
        display: none;
    }
}

.footer1 {
    text-decoration: underline;
    cursor: pointer;
    :not(:last-child)::after {
        content: '|';
        padding: 5px;
    }
    :focus-visible{
        outline: 2px solid black;
        border-radius: 2px;
    }
}
.footer2 {
    margin: 5px;
}
.containerCopyright {
    font-size: 12px;
    color: #476392;
    width: 100%;
    text-align: center;    
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
}

