@mixin bold {
    font-weight: bold;
}
@mixin flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    border: 0;
    position: relative;
    position: fixed;
}
.modalContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.06);
}
.mainContainer {
    @include flex;
    width: 550px;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    background-color: #ffffff;
    border: 2px solid #7690ab;
    padding: 0;
    top: 120px;
}
.mainContainerAccCreation {
    @include flex;
    width: 650px;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    background-color: #ffffff;
    border: none;
    padding: 40px;
    top: 60px;
    ul {
        padding-left: 15px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin: 0 0 0 2px;

        li {
            list-style: disc;
            padding-top: 3px;
            padding-left: 8px !important;
            margin-top: 10px;
            line-height: 18px;
        }
    }
}

.closeBtn {
    position: absolute;
    background-color: #193878;
    color: #ffff;
    border: none;
    border-radius: 3px;
    top: 10px;
    right: 10px;
}
.subText {
    color: #000000;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
}

.okBtn {
    background-color: #193878;
    color: #ffff;
    border: none;
    border-radius: 3px;
    padding: 4px;
}

.impNote{
    font-weight: bold;
}

.footerBtn{
    margin: 2% 40% 0 40%;
}

.underLine{
    font-weight: 550;
    text-decoration: underline;
}