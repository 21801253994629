@mixin alignCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin iconStyle {
    display: inline-block;
    text-align: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;
    vertical-align: middle;
}

.mainContainer {
    background-color: gradient(linear, left top, left bottom, from(#e5f2fc), to(#7ba4c4));
    background-image: linear-gradient(to bottom, #e5f2fc, #7ba4c4);
    width: 763px;
    border-radius: 10px;
    padding: 0 26px;
    padding-bottom: 19px;
    margin-top: 10px;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
}

.header {
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    color: #193878;
    padding-bottom: 8px;
}

span.icon {
    display: inline-block;
    text-align: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;
    vertical-align: middle;
    background-image: url(../../assets/images/img_bms_logo.png);
    width: 256px;
    height: 35px;
    padding: 15px 0px;
}

.title {
    font-size: 18px;
    font-weight: bold;
    padding: 10px 0px;
    align-self: flex-start;
}

.headerContainer {
    padding-top: 9px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.subHeader {
    font-size: 10px;
    text-align: left;
    padding: 0 10px;
    padding-bottom: 12px;
    margin-top: -4.5px;
}

.legalSymbol {
    font-size: 0.8em;
    position: relative;
    top: -0.5em;
}

.contentContainer {
    background-color: #dee9f2;
    border: 2px solid #5480a2;
    border-radius: 5px;
    padding: 15px 10px;
    display: grid;
    place-items: center;
    justify-content: center;
    grid-template-columns: 1fr 1.04fr 1fr;
}

.column {
    padding: 5px;
    @include alignCenter;
}

.column:nth-child(2) {
    border-left: 1.8px solid #7690ab;
    border-right: 1.8px solid #7690ab;
}

.productDisclaimerText {
    line-height: 1.25;
    font-size: 10px;
    color: #000000;
}

.center {
    text-align: center;
}

.bold {
    font-weight: bold;
}

.rems_program_lenalidomide {
    background-image: url(../../assets/images/img_RevlimidREMS_login.png);
}
.rems_program_pomalyst {
    background-image: url(../../assets/images/img_PomalidomideREMS_login.png);
}
.rems_program_thalomid {
    background-image: url(../../assets/images/img_ThalomidREMS_login.png);
}

.programImg {
    width: 178px;
    height: 72px;
    @include iconStyle;
    padding: 10px;
    cursor: pointer;
}

.pb5 {
    padding-bottom: 5px;
}

.slimPad {
    padding: 5px;
}

.productDisclaimerContainer {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 400px;
    border: 1px solid #7690ab;
    padding: 5px;
    margin-bottom: 12px;
}

.ul {
    padding-left: 15px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 0 0 0 2px;

    li {
        list-style: disc;
        padding-top: 3px;
        padding-left: 4px !important;
    }
}

.links {
    text-decoration: underline;
    color: #2b88b4;
    font-weight: normal;
    cursor: pointer;
}

.noDecoration {
    font-weight: bold;
    text-decoration: none;
}

.rightArrow {
    @include iconStyle();
    background-image: url(../../assets/images/btn_arrow_right.png);
    width: 4px;
    height: 7px;
}

.disabledProgram{
    filter: grayscale(1);
}