.generalTable {
    display: 'inline-block';
    margin: 1rem 0;
    background-color: #dee9f2;
    border: 2px solid #5480a2;
    border-radius: 5px;
    padding: 15px 10px;
    table {
        background-color: #dee9f2;
        border: 2px solid #5480a2;
        border-radius: 5px;
        padding: 15px 10px;
        border-collapse: collapse;
        font-family: 'Verdana', 'Arial', 'Helvetica', 'Clean', 'Sans-Serif';
        tr {
            th {
                color: #193878;
                border-right: 1px solid #7690ab;
                padding: 2px 5px 2px 5px;
                background-color: #ffffff;
                font-weight: bold;
                unicode-bidi: isolate;
                font-size: 10px;
                border-collapse: separate;
                text-indent: initial;
                border-spacing: 2px;
                line-height: normal; 
                height: auto;
                text-align: left;
            }
            td {
                padding: 1px 5px 1px 5px;
                background-color: #f1f5f8;
                font-size: 11px;
                color: #4b6075;
                border-right: 1px solid #7690ab;
                width: 20%;
                unicode-bidi: isolate;
                line-height: normal;
                height: auto; 
            }
        }
    }
}

.patientName {
    text-decoration: underline;
    color: #1565c0 !important;
    cursor: pointer;
}
