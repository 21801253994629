@mixin iconStyle {
    display: inline-block;
    text-align: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;
    vertical-align: middle;
}

@mixin printBold {
    font-size: 16px;
    font-weight: 700;
    font-family: 'Times New Roman';
    color:rgb(0, 0, 0);
}

@mixin printLight {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Times New Roman';
    color:rgb(0, 0, 0);
}

.logDispense {
    /*Common classes*/
    .textPadding {
        padding: 0px 5px;
        font-size: 12px;
        @media print {
            display: none;
        }
    }

    .bold {
        font-weight: bold;
        @media print{
            font-weight: 400;
            font-size: 16px;
            color:rgb(0, 0, 0);
        }
     }

    .pad_LR {
        padding-left: 10px;
        padding-right: 10px;
    }

    .instructions {
        font-weight: bold;
        padding: 10px 0px;
    }

    .required {
        color: #cc2800;
        padding: 0px 3px;
    }
    .requiredText {
        color: #cc2800;
    }
    .space {
        padding: 0px 6px;
    }

    .header {
        color: #193878;
        padding: 5px;
        padding-bottom: 8px;
    }

    .contentContainer {
        background-color: #dee9f2;
        border: 2px solid #5480a2;
        border-radius: 5px;
        padding: 15px 10px;
        margin-bottom: 10px;
        @media print {
            border: none;
        }
    }

    .flexContainer {
        display: flex;
        justify-content: space-between;
        margin: 16px 0;
    }

    /*selectDispense classes*/
    .selectDispense {
        margin: 0px -15px;

        .tooltip {
            position: relative;
            display: inline-block;
        }
        .icon {
            display: inline-block;
            text-align: center;
            background-repeat: no-repeat;
            background-color: transparent;
            background-position: center center;
            vertical-align: middle;
        }
        .tooltip:hover .tooltiptext {
            visibility: visible;
            z-index: 100;
        }
        .tooltip .tooltiptext {
            visibility: hidden;
            width: 230px;
            background-color: #193878;
            color: #fff;
            text-align: justify;
            border-radius: 6px;
            padding: 6px 6px 6px 6px;
            font-size: 0.75em;
            top: 20px;
            left: 10px;
            position: absolute;
        }
        .infoSymbol {
            background-image: url(../../../assets/images/infoIcon.png);
            width: 24px;
            height: 24px;
        }
        .deleteRow {
            display: flex;
            margin: 5px 0px;
        }

        .fields {
            margin-bottom: 4px;
        }
        .logDispenseTableDiv {
            position: relative;
            height: 360px;
            overflow-x: hidden;
            overflow-y: scroll;
            width: 100%;
            .splitHeader th.topHeader {
                border-bottom: 2px solid #7690ab;
                text-align: left;
                vertical-align: middle;
            }
            .logDispenseOdd {
                background-color: #f1f5f8;
            }
            .logDispenseEven {
                background-color: #ffffff;
            }
            th {
                color: #000000;
                padding: 3px;
                text-align: left;
            }
            td {
                color: #4b6075;
                padding: 3px;
                border-bottom: 1px solid #6c85a3;
                vertical-align: top;
            }
            a,
            a.active,
            a.visited,
            a.hover {
                color: #2b88b4;
                font-weight: normal;
                text-decoration: underline;
            }
            a.hover {
                text-decoration: underline;
            }
            .pharmacyLocationDropdown {
                height: 20px;
                width: 175px;
            }
            .quantityDropdown {
                width: 48px;
                height: 21px;
            }
            .quantityTextbox {
                width: 40px;
            }
            .authorization {
                width: 120px;
            }
        }
    }

    /*Review classes*/
    .review {
        .links {
            text-decoration: underline;
            cursor: pointer;
            @media print {
                text-decoration: none;
            }
        }
       
       
    }
    .footer_btn {
        display: flex;
        justify-content: space-between;
        @media print {
            display: none;
        }
    }
    .btn_group {
        width: 58%;
        display: flex;
        margin-top: 20px;
        justify-content: space-between;
    }
    /*Confirmation classes*/
    .confirmation {
        @media print {
            color: #000000 !important;
            padding: 30px !important;
        }

        
        .printRow {
            display: flex;
            margin: 12px 0px;
            align-items: flex-start;

            &_checkbox {
                margin: 5px 10px 0 0;
            }
        }

        .footer_btn {
            display: flex;
            justify-content: space-between;
            @media print {
                display: none;
            }
        }

        .legalSymbol {
            font-size: 0.8em;
            position: relative;
            top: -0.5em;
        }

        .AdobeInformation {
            width: 30%;
        }

        .alert{
            padding: 0 1%;
            @media print{
                display: none;
            }
        }

        .getAdobeReaderBtn {
            @include iconStyle;
            background-image: url(../../../assets/images/btn_Get_Adobe_Reader.png);
            width: 158px;
            height: 39px;
        }
        .btn_group {
            width: 65%;
            display: flex;
            margin-top: 40px;
            justify-content: space-between;
        }

        .links {
            text-decoration: underline;
            cursor: pointer;
            @media print {
                text-decoration: none;
            }
        }

        .action {
            text-decoration: none;
            @media print {
                text-decoration: none;
            }
        }
    }
}

.expand {
    display: flex;
    align-items: flex-start;
    &_icon {
        width: 11px;
        height: 11px;
        margin: 4px 2px 0 0;
        @media print {
            display: none;
        }
    }
}
.expandedRow {
    background-color: #dee9f2;
    margin: 0 1.5%;
    border: 2px solid #5480a2;
    padding: 1%;
    padding-bottom: 2%;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    @media print {
        border: none;
    }
    &_inputs {
        max-width: 42.5%;
        color: rgb(75, 96, 117);
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        @media print {
            row-gap: 5px;
        }

        

        &_item {
            display: flex;
            justify-content: space-between;
        }
        &_label {
            font-size: 12px;
            font-weight: 400;
            width: 44%;
            @media print {
                font-weight: bold;
            }
        }
        &_value {
            font-size: 12px;
            font-weight: 700;
            width: 52%;
            @media print {
                font-weight: 400;
            }
        }
        &_colon {
            font-weight: 400 !important;
            margin-right: 2px;
        }
    }

    &_inputs1 {
        color: rgb(75, 96, 117);
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        @media print {
            row-gap: 5px;
        }

        

        &_item {
            display: flex;
            justify-content: space-between;
        }
        &_label {
            font-size: 12px;
            font-weight: 400;
            width: 44%;
            @media print {
                @include printBold();
            }
        }
        &_value {
            font-size: 12px;
            font-weight: 700;
            width: 52%;
            @media print {
                @include printLight();
            }
        }
        &_colon {
            font-weight: 400 !important;
            margin-right: 2px;
        }
    }

    &_dispenseDetails {
        font-size: 12px;
        font-weight: 700;
        color: rgb(75, 96, 117);
        margin-bottom: 8px;
        @media print{
            @include printBold();
        }
    }
    &_table {
        min-width: 55%;
    }
}

.error {
    color: red;
    font-weight: bold;
}

.auth_no {
    
    color:rgb(75, 96, 117);
}


.products {
    display: flex;
    justify-content: space-between;
    color:rgb(75, 96, 117);
    &:not(:last-child){
        margin-bottom: 8px;
    }
}

.hidePrint{
    color: red;
    font-weight: bold;
    @media print{
        display: none;
    }

}


.required {
    color: '#CC2800';
    text-decoration: none !important;
    font-weight: bold;
}

input[type="checkbox"] {
 @media print{
        display: none;
    }
  }


  .pageHeader{
    font-size: 18px;
    font-weight: bold;
    display: none;
    @media print{
        display: block;
        margin: 25px 0 0 35px;
    }
  }

.printTable {
    display: none;
}


.timeoutText{
    padding: 0 !important;
}

.auth_status {
    font-weight: 700;
    color:rgb(75, 96, 117);
}

@media print{

    .printTable {
        display: block;
        width: 90%;
        margin-left: 30px;
        margin-top: 20px;
        border: 2px solid black;
    }
    .products,.auth_no,.links,.error,.required,.action{
        @include printLight();
    }

    .auth_status {
        @include printBold();
    }
}

.userAction{
    display: flex;
}


