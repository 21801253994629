@mixin button {
    border: 0;
    padding: 0;
    color: #fff;
    margin: 0;
    cursor: pointer;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: linear-gradient(180deg, #183975 0%, #15397C 13.54%, #183975 26.04%, #1B3E7A 52.6%, #234685 74.48%, #234685 88.54%, #12336A 100%);
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.35);
    position: relative;
    margin-bottom: 5px;

    .glossybtn {
        background: linear-gradient(180deg, #4385BD 0%, #81AFDF 25.52%, #3D66A0 51.04%, #20427E 73.44%, #193A76 100%);
        border-radius: 5px 5px 0px 0px;
        position: absolute;
        top: 1.5px;
        height: 9px;
        left: 3px;
        width: calc(100% - 6px);
    }

    p {
        margin: 0;
        padding-bottom: 6px;
        font-size:14px;
        font-weight: normal;
        padding-top: 6px;
        z-index: 99;
        font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    }
}
.contain{
    display: flex;
}
.btnWithImgContainer{
    button{
        justify-content: start;
        .insideText{
            margin: 0 0 0 90px;
            text-align: left;
            p{
                display: flex;
                margin-bottom: 10px;
                padding-top: 0;
            }
            .btnSubText{
                position: absolute;
                bottom: 5px;
                left: 90px;
                font-size: 14px;
                color: #FFF;
                }
        }
    }
}

@mixin yellowButton {
    background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#ffcf57));
    border: 2px solid #ffcf57;
    color: #14406a;
    cursor: pointer;
    font-weight: 600;
    margin-right: 6px;
    font-size: 12px;
    border-radius: 5%;
}
.disabledButton {
   // @include button;
   background-image: url(../../assets//images/btn_Send_to_Celgene_off.png);
   width:133px;
 
   border: 0;
    padding: 0;
    //width:12px;
    color: #fff;
    margin: 0;
    cursor:not-allowed;
    background-size: 100% 100%;
    background-repeat: no-repeat;
   
}
.buttonStyles {
    @include button;
}
.selectStyle{
    @include button;   
    background: rgb(123 123 123);
    color: #000000;
    .glossybtn {
        background: linear-gradient(180deg, #f0f0f0 0%, #cbcccd 28.52%, #b1b8bf 40.04%,#7b7b7b 61.04%, #7b7b7b 73.44%, #7b7b7b 100% );
    }
}
.sub_print_button {
    @include button;
}
.loginBtn {
    @include button();
}
.mr10 {
    @include button;
}
.yellowButtons {
    @include yellowButton();
    border-radius: 6px;
}

.firstBtn {
    @include yellowButton();
    margin-right: 13px;
}
.headerBtn{
    @include yellowButton();
    margin-right: 13px;
    border-radius: 6px;
}

.mr12 {
    @include button;
    margin-right: 12px;
}

.skyBlueBtn {
    @include button;
    border-radius: 7px;
    background: linear-gradient(180deg, #9CF 0%, #9CF 13.54%, #9CF 26.04%, #9CF 52.6%, #9CF 74.48%, #9CF 88.54%, #9CF 100%);
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.35);

    .glossybtn {
        border-radius: 5px 5px 0px 0px;
        background: linear-gradient(180deg, #99ccff 0%, #b9dcff 28.52%, #b3d9ff 61.04%, #A5D3FF 73.44%, #A2D1FF 100%);
        height: 12px;
    }

    p {
        color: #FFF;
    }
}

.redBtn {
    @include button;
    border-radius: 7px;
    background: #ef3a42;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.35);

    .glossybtn {
        border-radius: 5px 5px 0px 0px;
        background: linear-gradient(0deg, #ef3a42 0%, #f15961 28.52%, #f3777d 61.04%, #f3777d 73.44%, #f15d64 100%);
        height: 12px;
    }

    p {
        color: #FFF;
    }
}

.tealBtn {
    @include button;
    border-radius: 7px;
    background: rgb(0, 89, 120);
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.35);

    .glossybtn {
        border-radius: 5px 5px 0px 0px;
        background: linear-gradient(180deg, #1892CE 0%, #148AC3 25.52%, #1B7BAA 51.04%, #18709B 73.44%, #06577A 100%);
        height: 12px;
    }
    p {
        color: #FFF;
    }
}

.pomalystBtn {
    @include button;
    border-radius: 7px;
    background: #007482;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.35);

    .glossybtn {
        border-radius: 5px 5px 0px 0px;
        background: linear-gradient(180deg, #007482 0%, #52a1aa 28.52%, #52a1aa 61.04%, #3c8d98 73.44%, #007482 100%);
        height: 12px;
    }
    p {
        color: #FFF;
    }
}

.prodBtnDisabled{
    @extend .tealBtn;
    background: rgb(168 168 168);
    .glossybtn{
        background: linear-gradient(180deg, #f0f0f0 0%, #cbcccd 28.52%, #b1b8bf 61.04%, #b1b1b1 73.44%, #a5a5a5 100%);
    }
}

.disabledPom{
    @extend .tealBtn;
    background: rgb(88, 88, 88);
    .glossybtn{
    background: linear-gradient(180deg, #c3b9b9 0%, #9b9898 28.52%, #5e6062 61.04%, #5b5858 73.44%, #585858 100%);
    }
}
.disabledLen{
    @extend .skyBlueBtn;
    background: linear-gradient(180deg, #BFD9F3 0%, #BED8F3 13.54%, #C3DEF8 26.04%, #BFD7F0 52.6%, #AFC9E3 74.48%, #ABC3DB 88.54%, #A5BCD1 100%);
    .glossybtn{
        background: linear-gradient(180deg, #D4E8FC 0%, #D2E5F6 25.52%, #CEE4FB 51.04%, #CDE1F6 73.44%, #C3DDF6 100%);
    }
}
.disabledThal{
    @extend .redBtn;
        background: linear-gradient(180deg, #ECC9CB 0%, #ECC9CB 13.54%, #ECC9CB 26.04%, #E5C3C4 52.6%, #D3B3B4 74.48%, #C6A4A6 88.54%, #BE9C9D 100%);
    .glossybtn{
        background: linear-gradient(180deg, #EFD6D8 0%, #F1D9DB 25.52%, #EFD3D5 51.04%, #EECFD1 73.44%, #E9C7C8 100%);
    }
}