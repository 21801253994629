@mixin iconStyle {
    display: inline-block;
    text-align: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;
    vertical-align: middle;
    padding: 10px;
    width: 235px;
    height: 41px;
}
.mainContainer {
    background-color: #fff;
    border: 1px solid #ddd;
    font-size: 12px;
    width: 815px;
    padding: 0 5px;
    border-radius: 10px;
    .button_wrapper{
    .btnWithImage{
        position: relative;
        margin: 0 0 3px 0;
        .innerImage{
            position: absolute;
            z-index: 9;
            width: 70px;
            background-size: 100% !important;
            height: 45px;
            background-repeat: no-repeat !important;
            left: 18px;
            top: 18px;
            &.prod_Lenalidomide{
                background: url(../../assets/images/program/lenalidomide/patient_reg.png);
            }
            &.prod_Thalomid{
                background: url(../../assets/images/program/thalomid/patient_reg.png);
            }
            &.prod_Pomalyst{
                background: url(../../assets/images/program/pomalyst/patient_reg_pomalyst.png);
            }
            &.prod_Pomalidomide{
                background: url(../../assets/images/program/pomalyst/patient_reg.png);
            }
            &.prodSubmittedPPAF_Lenalidomide{
                background: url(../../assets/images/program/lenalidomide/icon_saved_blue.png);
            }
            &.prodSubmittedPPAF_Thalomid{
                background: url(../../assets/images/program/thalomid/icon_saved_red.png);
            }
            &.prodSubmittedPPAF_Pomalyst{
                background: url(../../assets/images/program/pomalyst/icon_saved_green_pomalyst.png);
            }
            &.prodSubmittedPPAF_Pomalidomide{
                background: url(../../assets/images/program/pomalyst/icon_saved_green.png);
            }
            
        }
        p{
            margin-bottom: 0 !important;
            padding-right: 37px !important;
            padding-left: 20px !important;
            padding-bottom:0;
        }
        span{
            bottom: 2px;
            left: 108px;
        }
    }
       
    }
}
@mixin buttons {
    display: flex;
    justify-content: center;
    margin: 12px;
}
.rems_program_lenalidomide {
    .en {
        .programLogo {
            @include iconStyle;
            background-image: url(../../assets/images/img_RevAssist_transparent.png);
        }
        .headerDivider {
            border-bottom: 2px solid #fec110;
        }
        .button_wrapper {
            @include buttons;
        }
    }
    .es {
        .programLogo {
            @include iconStyle;
            background-image: url(../../assets/images/img_RevAssist_transparent.png);
        }
        .headerDivider {
            border-bottom: 2px solid #fec110;
        }
        .button_wrapper {
            @include buttons;
        }
    }
}
.rems_program_pomalyst {
    .en {
        .programLogo {
            @include iconStyle;
            background-image: url(../../assets/images/Image_PomalidomideREMS_Transparent.png);
        }

        .headerDivider {
            border-bottom: 2px solid #007482;
        }
        .button_wrapper {
            @include buttons;
        }
    }
    .es {
        .programLogo {
            @include iconStyle;
            background-image: url(../../assets/images/Image_PomalidomideREMS_Transparent.png);
        }

        .headerDivider {
            border-bottom: 2px solid #007482;
        }
        .button_wrapper {
            @include buttons;
        }
    }
}

.rems_program_thalomid {
    .en {
        .programLogo {
            @include iconStyle;
            background-image: url(../../assets/images/img_STEPS_transparent.png);
        }

        .headerDivider {
            border-bottom: 2px solid #f13945;
        }
        .button_wrapper {
            @include buttons;
        }
    }
    .es {
        .programLogo {
            @include iconStyle;
            background-image: url(../../assets/images/img_STEPS_transparent.png);
        }

        .headerDivider{
            border-bottom: 2px solid #f13945;
        }
        .button_wrapper {
            @include buttons;
        }
    }
}
.generalProgramLogo {
    @include iconStyle;
    width: 256px;
    height: 40px;
    padding-left: 2px;
    padding-bottom: 2px;
}
.headerDivider {
    border-bottom: 2px solid;
    padding-top: 5px;
}
.button_wrapper {
    @include buttons;
}
.mb20{
    margin-bottom: 20px;
}
