@mixin iconStyle {
    display: inline-block;
    text-align: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;
    vertical-align: middle;
}

@mixin btnStyles {
    width: 115px;
    border: none;
    cursor: pointer;
    background-repeat: no-repeat;
    height: 50px;
    margin: 10px 20px;
}

.mainContainer {
    background-color: #ffffff;
    width: 763px;
    border-radius: 10px;
    padding: 0 26px;
    padding-bottom: 19px;
    padding-top: 9px;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    color: #193878;
    box-shadow: 0px 3px 1px rgba(25, 56, 120, 0.3);
}

.headerContainer {
    padding-top: 9px;
}
.headerNote {
    color: #000;
    margin: 10px 25px 0 0;
}
.note {
    padding: 5px;
    font-style: italic;
    text-align: end;
    padding-top: 10px;
    color: #000;
    span {
        color: #7b0305;
    }
}
.headerDescription{
    padding: 5px;
    font-style: italic;
    text-align: end;
    padding-top: 10px;
    color: #000;
}
.required {
    color: #7b0305;
}
.riskError {
    font-size: 0.9em;
    background: #ffffcc;
    border: 1px solid #ffe640;
    margin: 10px 30px 5px 30px;
    padding: 10px;
}

.contentContainer {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    margin-bottom: 12px;
    div {
        color: #000000;
    }
}

.helpLink_postion {
    position: relative;
}
.helpLink {
    position: absolute;
    left: 455px;
    top: 8px;
    color: #2b88b4;
    cursor: pointer;
    text-decoration: underline;
}

.productHeader {
    color: #666;
    line-height: 18px;
    font-style: italic;
    font-weight: bold;
    padding: 7px 0;
    border-bottom: 1px solid #c0c0c0;
}

.programlogo {
    @include iconStyle;
    width: 250px;
    height: 41px;
    padding: 5px 0px;
    align-self: flex-end;
}
.fontBold {
    font-weight: bold;
    max-width: 470px;
    word-wrap: break-word;
}

/* styles based on program*/

.rems_program_thalomid {
    .programlogo {
        background-image: url(../../assets/images/img_STEPS_transparent.png);
    }
    .headerDivider {
        border-bottom: 2px solid #f13945;
    }

    .btn_Save {
        background-image: url(../../assets/images/program/thalomid/btn_Continue.png);
        @include btnStyles;
    }
    .btn_Save_es {
        background-image: url(../../assets/images/program/es/thalomid/btn_Continue.png);
        @include btnStyles;
        // width: 93px;
    }
}

.rems_program_lenalidomide {
    .programlogo {
        background-image: url(../../assets/images/img_RevAssist_transparent.png);
    }

    .headerDivider {
        border-bottom: 2px solid #fec110;
    }

    .btn_Save {
        background-image: url(../../assets/images/program/lenalidomide/btn_Continue.png);
        @include btnStyles;
    }
    .btn_Save_es {
        background-image: url(../../assets/images/program/es/lenalidomide/btn_Continue.png);
        @include btnStyles;
        // width: 93px;
    }
}

.rems_program_pomalyst {
    .programlogo {
        background-image: url(../../assets/images/Image_PomalidomideREMS_Transparent.png);
    }

    .headerDivider {
        border-bottom: 2px solid #007482;
    }
    .btn_Save {
        background-image: url(../../assets/images/program/pomalyst/btn_Continue.png);
        @include btnStyles;
    }
    .btn_Save_es {
        background-image: url(../../assets/images/program/es/pomalyst/btn_Continue.png);
        @include btnStyles;
        // width: 93px;
    }
}
.generalProgramLogo {
    @include iconStyle;
    width: 256px;
    height: 40px;
    padding-left: 2px;
    padding-bottom: 2px;
}
.headerDivider {
    border-bottom: 2px solid;
    padding-top: 5px;
}
.container {
    display: grid;
    grid-template-columns: 0.8fr 1.3fr;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    color: #193878;
    margin-top:2px;
}
.input {
    margin: 5px;
    max-width: 470px;
    word-wrap: break-word;
}
.mandatory {
    &::before {
        content: '* ';
        color: #7b0305;
    }
}
.label {
    text-align: right;
    margin: 5px;
}
.helpTextSel {
    font-size: 11px;
    margin: 0;
    font-weight: normal;
}
.divider{
    margin: 15px 25px;
    border-bottom:1px solid #c0c0c0;
}
.inputZip{
    width:65px;
    margin-right: 5px;;
}
.inputZipExt{
    width:50px;
    margin-left:5px;
}
.radioBtn{
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 10px;
}

.shipDirect{
    margin-left: 30px;
    accent-color: #616161;
}
.btnNext{
    margin-top:10px ;
    margin-bottom:10px ;
    position: relative;
    left: 22px;
}
.nxtBtn{
    position: relative;
    left: 20px;
   /*  bottom: 10px; */
    width: 137px;
    height: 55px;
    background-repeat: no-repeat;
    border: 0;
}
.nxtBtn_rems_program_lenalidomide_en{
    background-image: url(../../assets/images/program/lenalidomide/btn_Next.png);  
}

.nxtBtn_rems_program_pomalyst_en{
    background-image: url(../../assets/images/program/pomalyst/btn_Next.png);
}

.nxtBtn_rems_program_thalomid_en{
    background-image: url(../../assets/images/program/thalomid/btn_Next.png);
}
.addressP{
    margin:0;
    font-weight: 600;
}

/*Dosage*/
.containerDosage {
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    color: #193878;
    margin-top:2px;
    padding: 15px;
}
.directionsRow{
    align-content: center;
}
.dosageCol{
    align-content: center;
}
.dosageRow{
    padding-bottom: 10px;
}
.quantity{
    width:50px;
}
.radioBtnDispense{
    
    display: flex;
    align-items: center;
    padding: 10px 0;
    padding-bottom: 0;
}
.getAdobe{
    background-image: url('../../assets//images/btn_Get_Adobe_Reader.png');
    width: 158px;
    height: 39px;
    margin-top: 20px;
   // padding:0 150px 20px 0;
   
}

.prevBtn_rems_program_lenalidomide_en{
    background-image: url(../../assets/images/program/lenalidomide/btn_Previous.png);  
    width: 112px;
    margin-right: 7px ;
}
.prevBtn_rems_program_pomalyst_en{
    background-image: url(../../assets/images/program/pomalyst/btn_Previous_Pom.png);
    width: 112px;
    margin-right: 7px ;
}
.prevBtn_rems_program_thalomid_en{
    background-image: url(../../assets/images/program/thalomid/btn_Previous_thal.png);
    width: 112px;
    margin-right: 7px ;
    height: 54px;
}
.printBtn_rems_program_lenalidomide_en{
    background-image: url(../../assets/images/program/lenalidomide/btn_Print.png);  
    width: 112px;
    margin-right: 7px ;
}
.printBtn_rems_program_pomalyst_en{
    background-image: url(../../assets/images/program/pomalyst/btn_Print.png);
    width: 112px;
    margin-right: 7px ;
}
.printBtn_rems_program_thalomid_en{
    background-image: url(../../assets/images/program/thalomid/btn_Print.png);
    width: 112px;
    margin-right: 7px ;
    height: 54px;
}
.returnBtn_rems_program_lenalidomide_en{
    background-image: url(../../assets/images/program/lenalidomide/btn_wp_Return_to_Prescriber_Dashboard_REV.png);  
    width: 265px;
    margin-right: 7px ;
}
.returnBtn_rems_program_pomalyst_en{
    background-image: url(../../assets/images/program/pomalyst/btn_wp_Return_to_Prescriber_Dashboard_POM.png);
    width: 265px;
    margin-right: 7px ;
}
.returnBtn_rems_program_thalomid_en{
    background-image: url(../../assets/images/program/thalomid/btn_wp_Return_to_Prescriber_Dashboard_THAL.png);
    width: 265px;
    margin-right: 7px ;
    height: 54px;
}
.btnCol{
    align-content: end;
}
.authMessage{
    font-size: 12px;
    font-weight: bold;
    padding:10px 0;
}
.paddingClass{
    padding:10px 0;
    &:first-child{
        padding-top: 0;
    }
}
.prescInfo{
    color:#2B88B4;
    cursor: pointer;
    text-decoration: underline;
}
.directions{
    float: right;
    position: relative;
    right: 10px;

}
.adobeText{
    font-size: 12px;
    font-weight: 400;
}
.adobeP{
    margin-top:0;
    width:200px;
}
.dosageLabel{
    font-size: 12px;
    font-weight: 400;
}
.qtyLabel
{
    font-size: 12px;
    font-weight: 400;
    padding-right: 5px;
}
.dosageCheckBox{
    position: relative;
    top: 2px;
    accent-color: #616161;
}
.dosageDrpDwn{
    width: 60px;
    font-size: 12px;
}
.required {
    color: #7b0305;
    position: relative;
    right: 5px;
    margin-left: -6px;
}
.requiredOthr {
    color: #7b0305;
    position: relative;
    right: 5px;
}
.requiredPrd{
    color: #7b0305;
}
.accentColor{
    accent-color: #616161;
}
.directionsInput{
    font-size: 12px;
}
.prdSelSpan{
    margin-right:30px;
}
.dosageBtnPosition{
    position: relative;
    bottom: 10px;
}

.generalProgramLogo {
    @include iconStyle;
    width: 256px;
    height: 40px;
    padding-left: 2px;
    padding-bottom: 2px;
}