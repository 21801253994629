.header {
    width: 815px;
    margin: 15px;
    margin-bottom: 5px;
    @media print{
        display: none;
    }
}
.headerRight{
    float:right;
}
.headerUserName{
    color: #FDFFA4;
    font-size: 12px;
    float:left;
    position: relative;
    top: 5px;
}
