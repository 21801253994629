.reviewAuthorization {
    .textPadding {
        padding: 0px 5px;
        font-size: 12px;
    }

    .buttonWrapper {
        margin: 5px 0px;
        display: flex;
        column-gap: 5px;
        &.buttonStyles {
            margin-left: 30px;
        }
    }
    .programTitle {
        padding: 12px;
        padding-bottom: 16px;
        font-size: 12px;
        text-align: center;
    }

    .buffer {
        padding: 2px;
        font-size: 8px;
        line-height: 8px;
    }

    .selectREMSProgram {
        color: #193878;
        font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
        font-size: 12px;
        margin: 8px 0 0 0;
        font-weight: bold;
        padding-bottom: 10px;
    }

    .contentContainer {
        background-color: #dee9f2;
        border: 2px solid #5480a2;
        border-radius: 5px;
        padding: 15px 10px;
        // background: -webkit-gradient(linear, left top, left bottom, from(#aecee8), to(#f5faff));
    }
    .prgmContainer {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 15px 0px;
        padding: 0px 15px;
    }
    .prgm {
        border: 2px solid #5480a2;
        border-radius: 10px;
        width: 200px;
        height: 62px;
        align-content: center;
        text-align: center;
        font-size: 10px;

        > span {
            color: #2b88b4;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .legalSymbol {
        font-size: 0.8em;
        position: relative;
        top: -0.5em;
    }
    a,
    a.active,
    a.visited,
    a.hover {
        color: #2b88b4;
        font-weight: normal;
        text-decoration: underline;
    }
    .prodInfoText {
        line-height: 1.3;
        font-size: 11px;
        text-align: center;
    }
    .columnFlex {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .actCls {
            margin-bottom: 12px;
            cursor: pointer;
            width: 400px;
        }
    }

    .center {
        text-align: center;
    }

    .bold {
        font-weight: bold;
    }
  
   
    .colGroup1{
        font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    }
}

.generalTable {
    display: 'inline-block';
    margin: 1rem 0;
    background-color: #dee9f2;
    border: 2px solid #5480a2;
    border-radius: 5px;
    padding: 15px 10px;
    table {
        background-color: #dee9f2;
        border: 2px solid #5480a2;
        border-radius: 5px;
        padding: 15px 10px;
        border-collapse: collapse;
        font-family: 'Verdana', 'Arial', 'Helvetica', 'Clean', 'Sans-Serif';
        tr {
            th {
                color: #193878;
                border-right: 1px solid #7690ab;
                padding: 2px 5px 2px 5px;
                background-color: #ffffff;
                font-weight: bold;
                unicode-bidi: isolate;
                font-size: 10px;
                border-collapse: separate;
                text-indent: initial;
                border-spacing: 2px;
                line-height: normal; 
                height: auto;
                text-align: left;
            }
            td {
                padding: 1px 5px 1px 5px;
                background-color: #f1f5f8;
                font-size: 11px;
                color: #4b6075;
                border-right: 1px solid #7690ab;
                width: 20%;
                unicode-bidi: isolate;
                line-height: normal;
                height: auto; 
            }
        }
    }
}

.patientName {
    text-decoration: underline;
    color: #1565c0 !important;
    cursor: pointer;
}

.detailedPatientAuthData {
    background-color: #dee9f2;
    border: 2px solid #5480a2;
    border-radius: 5px;
    padding: 15px 10px;
    margin: 16px 0;
    display: flex;
    flex-direction: column;
}

.topRow {
    :global {
        display: flex;
        justify-content: space-around;

        table.leftCol {
            transform: translateX(-25px);
        }

        table.rightCol {
            transform: translateX(-80px);
        }

        table.leftCol tr > td:nth-child(1),
        table.rightCol tr > td:nth-child(1) {
            text-align: right;
            padding: 7px 0;
            margin-right: 2px;
            font-size: 11px;
            color: #4b6075;
            border-spacing: 2px;
            vertical-align: top;
        }
        table.leftCol tr > td:nth-child(2),
        table.rightCol tr > td:nth-child(2) {
            text-align: left;
            padding: 7px 0;
            font-size: 11px;
            color: #4b6075;
            font-weight: bold;
            padding-left: 15px;
            vertical-align: top;
            min-width: 75px;
        }
    }
}