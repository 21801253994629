.detailedPatientAuthData {
    background-color: #dee9f2;
    border: 2px solid #5480a2;
    border-radius: 5px;
    padding: 15px 10px;
    margin: 16px 0;
    margin-top:7px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
}

.topRow {
    :global {
        display: flex;
        justify-content: space-around;

        table.leftCol {
            transform: translateX(-25px);
        }

        table.rightCol {
            transform: translateX(-80px);
        }

        table.leftCol tr > td:nth-child(1),
        table.rightCol tr > td:nth-child(1) {
            text-align: right;
            padding: 7px 0;
            margin-right: 2px;
            font-size: 11px;
            color: #4b6075;
            border-spacing: 2px;
            vertical-align: top;
        }
        table.leftCol tr > td:nth-child(2),
        table.rightCol tr > td:nth-child(2) {
            text-align: left;
            padding: 7px 0;
            font-size: 11px;
            color: #4b6075;
            font-weight: bold;
            padding-left: 15px;
            vertical-align: top;
            min-width: 75px;
        }
    }
}

.middleRow {
    :global {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 7rem;
        max-width: max-content;
        .dispenseTable {
            font-family: 'Verdana', 'Arial', 'Helvetica', 'Clean', 'Sans-Serif';
            .label {
                font-size: 12px;
                color: #4b6075;
                font-weight: bold;
                margin-bottom: 5px;
            }
            table {
                font-size: 10px;
                border: 2px solid #7690ab;
                background-color: #ffffff;
                width: 300px;
                border-collapse: collapse;
                tr {
                    vertical-align: top;
                    
                    td {
                        padding: 5px 10px;
                        text-align: left;
                        color: #4B6075;
                        background-color: #F1F5F8;
                        font-size: 11px;
                    }
                    th:nth-child(1),
                    td:nth-child(1) {
                        border-right: 1px solid #7690ab;
                    }
                    th {
                        font-size: 10px;
                        color: #193878;
                        padding: 10px;
                        text-align: left;
                        background-color: #FFFFFF;
                    }
                }
            }
        }
        .pharmacyHyperLink {
            .label {
                margin-right: 2px;
                font-size: 11px;
                color: #4b6075;
                border-spacing: 2px;
                transform: translateX(-1px);
                width: 37%;
                font-family: 'Verdana', 'Arial', 'Helvetica', 'Clean', 'Sans-Serif';
            }

            display: flex;
            align-items: baseline;
            .pharmacyName {
                text-decoration: underline;
                color: #1565c0;
                font-size: 12px;
                font-weight: bold;
                cursor: pointer;
            }
            .pharmacyDetailsPopUp {
                transform: translateX(-15px);
                background-color: #fff;
                padding: 15px 8px 8px 4px;
                border: 2px solid #7690ab;
                td:nth-child(1) {
                    font-size: 10px;
                    vertical-align: top;
                    text-align: right;
                    color: #4b6075;
                }
                td:nth-child(2) {
                    word-wrap: break-word; 
                    white-space: pre-wrap;
                    vertical-align: top;
                    text-align: left;
                    padding-left: 6px;
                    padding-right: 2px;
                    font-weight: bold;
                    font-size: 10px;
                    color: #4b6075;
                    max-width: 108px;
                }
            }
            .closeIcon {
                cursor: pointer;
                position: absolute;
                top: 3px;
                right: 7px;
            }
        }
    }
}

.bottomRow {
    display: flex;
    justify-content: left;
    margin: 10px 0 0 0;
}

.buttonWrapper {
    margin-top: 10px;
    margin-left: 1px;
}
