.container{
    width:759px;
    //min-height:305.33px;
    background-color: #fff;
    border-radius: 10px;
    padding:12px 28px;
}

@mixin iconStyle {
    display: inline-block;
    text-align: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;
    vertical-align: middle;
}

.headerContainer{
    margin-bottom: 8px;
}

.productHeader{
    color: #666;
    line-height: 18px;
    font-style: italic;
    font-weight: bold;
    font-size: 12px;
    padding-bottom: 5px;

}

.contentDivider{
    border-top: 1px solid #C0C0C0;
    padding-top: 15px;
}

.mainContainer{
    display: flex;
    flex-direction: column;
    min-width:735px;
    min-height:182.67px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    padding: 15px 10px 5px 10px;
    color:#000 !important;

    &_inputs{
        margin-left: 180px;
    }

}

.textContainer{
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
    row-gap: 14px;
    margin-top: 18px;
    margin:18px 10px 0;
    text-align: center;
}

.textContent{
    font-weight: bold;
    font-size: 12px;
}

.print{
   display: flex;
   justify-content: center;
   font-size: 12px;
   column-gap: 50px;
   margin-top: 20px;
   margin-left: 150px;

}

.print_flag{
    display: flex;
    justify-content: center;
    font-size: 12px;
    column-gap: 50px;
    margin-top: 20px;
    margin-left: 30px;
 
 }

.flag_btn{
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    margin-left: 20px;

}
.legalSymbol {
    font-size: 0.8em;
    position: relative;
    top: -0.5em;
}

.AdobeInformation {
    width: 300px;
}

.getAdobeReaderBtn {
    @include iconStyle;
    background-image: url(../../assets/images/btn_Get_Adobe_Reader.png);
    width: 158px;
    height: 39px;
}

.btn_group{
    display: flex;
    column-gap: 30px;
    padding-top: 20px;
    justify-content: center;
}

.btn_group_flag{
    display: flex;
    padding-top: 20px;
    justify-content: flex-start;
}


    .btn{
        border: none;
        cursor: pointer;
        //background-size: cover;
        height:49px;
        //display: inline-block;
        text-align: center;
        background-repeat: no-repeat;
        background-color: transparent;
        background-position: center center;
        vertical-align: middle;
    }





.btn_Print_len{
    background-image: url(../../assets/images/program/lenalidomide/btn_Print.png);
    min-width:112px;
    
}
.btn_Patient_Survey_len{
    background-image: url(../../assets/images/program/lenalidomide/btn_Patient_Survey.png);
    min-width:193px;
    
}
.btn_Prescriber_Survey_len{
    background-image: url(../../assets/images/program/lenalidomide/btn_Prescriber_Survey.png);
    min-width:193px;
    
}
.btn_Write_pres_len{
    background-image: url(../../assets/images/program/lenalidomide/btn_Write_Prescription.png);
    min-width:193px;
    
}
.btn_Continue_len{
    background-image: url(../../assets/images/program/lenalidomide/btn_Continue.png);
    min-width:107px;
    
}
.btn_Print_thal{
    background-image: url(../../assets/images/program/thalomid/btn_Print.png);
    min-width:107px;
    
}
.btn_Patient_Survey_thal{
    background-image: url(../../assets/images/program/thalomid/btn_Patient_Survey.png);
    min-width:193px;
    
}
.btn_Prescriber_Survey_thal{
    background-image: url(../../assets/images/program/thalomid/btn_Prescriber_Survey.png);
    min-width:193px;
    
}
.btn_Write_pres_thal{
    background-image: url(../../assets/images/program/thalomid/btn_Write_Prescription.png);
    min-width:193px;
    
}
.btn_Continue_thal{
    background-image: url(../../assets/images/program/thalomid/btn_Continue.png);
    min-width:107px;
    
}
.btn_Print_pom{
    background-image: url(../../assets/images/program/pomalyst/btn_Print.png);
    min-width:112px;
    
}
.btn_Patient_Survey_pom{
    background-image: url(../../assets/images/program/pomalyst/btn_Patient_Survey.png);
    min-width:193px;
    
}
.btn_Prescriber_Survey_pom{
    background-image: url(../../assets/images/program/pomalyst/btn_Prescriber_Survey.png);
    min-width:193px;
    
}
.btn_Write_pres_pom{
    background-image: url(../../assets/images/program/pomalyst/btn_Write_Prescription.png);
    min-width:193px;
    
}
.btn_Continue_pom{
    background-image: url(../../assets/images/program/pomalyst/btn_Continue.png);
    min-width:112px;
    
}
.btn_Print_len_es{
    background-image: url(../../assets/images/program/es/lenalidomide/btn_Print.png);
    min-width:121px;
    
}
.btn_Patient_Survey_len_es{
    background-image: url(../../assets/images/program/es/lenalidomide/btn_Patient_Survey.png);
    min-width:157px;
    
}
.btn_Prescriber_Survey_len_es{
    background-image: url(../../assets/images/program/es/lenalidomide/btn_Prescriber_Survey.png);
    min-width:265px;
    
}
.btn_Write_pres_len_es{
    background-image: url(../../assets/images/program/es/lenalidomide/btn_Write_Prescription.png);
    min-width:193px;
    
}
.btn_Continue_len_es{
    background-image: url(../../assets/images/program/es/lenalidomide/btn_Continue.png);
    min-width:107px;
    
}
.btn_Print_thal_es{
    background-image: url(../../assets/images/program/es/thalomid/btn_Print.png);
    min-width:121px;
    
}
.btn_Patient_Survey_thal_es{
    background-image: url(../../assets/images/program/es/thalomid/btn_Patient_Survey.png);
    min-width:157px;
    
}
.btn_Prescriber_Survey_thal_es{
    background-image: url(../../assets/images/program/es/thalomid/btn_Prescriber_Survey.png);
    min-width:265px;
    
}
.btn_Write_pres_thal_es{
    background-image: url(../../assets/images/program/es/thalomid/btn_Write_Prescription.png);
    min-width:193px;
    
}
.btn_Continue_thal_es{
    background-image: url(../../assets/images/program/es/thalomid/btn_Continue.png);
    min-width:107px;
    
}
.btn_Print_pom_es{
    background-image: url(../../assets/images/program/es/pomalyst/btn_Print.png);
    min-width:121px;
    
}
.btn_Patient_Survey_pom_es{
    background-image: url(../../assets/images/program/es/pomalyst/btn_Patient_Survey.png);
    min-width:157px;
    
}
.btn_Prescriber_Survey_pom_es{
    background-image: url(../../assets/images/program/es/pomalyst/btn_Prescriber_Survey.png);
    min-width:265px;
    
}
.btn_Write_pres_pom_es{
    background-image: url(../../assets/images/program/es/pomalyst/btn_Write_Prescription.png);
    min-width:193px;
    
}
.btn_Continue_pom_es{
    background-image: url(../../assets/images/program/es/pomalyst/btn_Continue.png);
    min-width:112px;
    
}
@mixin iconStyle {
    display: inline-block;
    text-align: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;
    vertical-align: middle;
}

.programlogo {
    @include iconStyle;
    min-width: 250px;
    height: 41px;
    padding: 5px 0px;
    align-self: flex-end;
}

.risk_warning{
    font-size: 0.7em;
    margin: 5px;
    text-align: center;
    font-weight: bold;
}

/* styles based on program*/

.rems_program_thalomid {
    .programlogo {
        background-image: url(../../assets/images/img_STEPS_transparent.png);
    }
    .headerDivider {
        border-bottom: 2px solid #f13945;
    }
    
}

.rems_program_lenalidomide {
    .programlogo {
        background-image: url(../../assets/images/img_RevAssist_transparent.png);
    }

    .headerDivider {
        border-bottom: 2px solid #fec110;
    }
   
}

.rems_program_pomalyst {
    .programlogo {
        background-image: url(../../assets/images/Image_PomalidomideREMS_Transparent.png);
    }

    .headerDivider {
        border-bottom: 2px solid #007482;
    }
   
}
.generalProgramLogo {
    @include iconStyle;
    width: 256px;
    height: 40px;
    padding-left: 2px;
    padding-bottom: 2px;
}
.headerDivider {
    border-bottom: 2px solid;
    padding-top: 5px;
}