.educationAndCounseling {
    .textPadding {
        padding: 0px 5px;
        font-size: 12px;
    }

    .programTitle {
        padding: 12px;
        padding-bottom: 16px;
        font-size: 12px;
        text-align: center;
    }

    .buffer {
        padding: 2px;
        font-size: 8px;
        line-height: 8px;
    }

    .selectREMSProgram {
        color: #193878;
        font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
        font-size: 12px;
        margin: 8px 0 0 0;
        font-weight: bold;
        padding-bottom: 10px;
    }

    .contentContainer {
        background-color: #dee9f2;
        border: 2px solid #5480a2;
        border-radius: 5px;
        padding: 15px 10px;
        // background: -webkit-gradient(linear, left top, left bottom, from(#aecee8), to(#f5faff));
    }
    .prgmContainer {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 15px 0px;
        padding: 0px 15px;
    }
    .prgm {
        border: 2px solid #5480a2;
        border-radius: 10px;
        width: 200px;
        height: 62px;
        align-content: center;
        text-align: center;
        font-size: 10px;

        > span {
            color: #2b88b4;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .legalSymbol {
        font-size: 0.8em;
        position: relative;
        top: -0.5em;
    }
    a,
    a.active,
    a.visited,
    a.hover {
        color: #2b88b4;
        font-weight: normal;
        text-decoration: underline;
    }
    .prodInfoText {
        line-height: 1.3;
        font-size: 11px;
        text-align: center;
    }
    .columnFlex {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .actCls {
            margin-bottom: 12px;
            cursor: pointer;
            width: 400px;
        }
    }

    .center {
        text-align: center;
    }

    .bold {
        font-weight: bold;
    }
}

.btn{
    border: none;
    cursor: pointer;
    height:74px;
    text-align: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;
    vertical-align: middle;
    min-width: 180px;
}

.Pom{
    background-image: url(../../../assets/images/img_PomalidomideREMS_login.png);
}
.Len{
    background-image: url(../../../assets/images/img_RevlimidREMS_login.png);
   
}

.Thal{
    background-image: url(../../../assets/images/img_ThalomidREMS_login.png);
   
}
