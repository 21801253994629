.loginHome {

    .textPadding {
        padding: 0px 5px;
        font-size: 12px;

    }

    .programTitle {
        padding: 12px;
        padding-bottom: 16px;
        font-size: 12px;
        text-align: center;
    }

    .buffer {
        padding: 2px;
        font-size: 8px;
        line-height: 8px;
    }

    .contentContainer {
        background-color: #dee9f2;
        border: 2px solid #5480a2;
        border-radius: 5px;
        padding: 15px 10px;
        background: -webkit-gradient(linear, left top, left bottom, from(#aecee8), to(#f5faff));
    }

    .programContainer {
        border: 3px solid white;
        border-radius: 5px;
        width: 215px;
        padding: 10px;
        text-align: center;
        font-size: 10px;
        background: -webkit-gradient(linear, left top, left bottom, from(#aecee8), to(#f5faff));
        display: flex;
        flex-direction: column;
        align-items: center;

        >img {
            width: 178px;
            height: 72px;
            cursor: pointer;
            margin-bottom: 2px;
        }

        >p {
            margin: 0;
            width: 194px;            
        }
    }

    .taskContainer {
        border: 3px solid white;
        border-radius: 5px;
        width: 171px;
        height: 150px;
        padding: 10px 20px 5px 20px;
        font-size: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        background: -webkit-gradient(linear, left top, left bottom, from(#aecee8), to(#f5faff));

        >p {
            margin: 0;

            >span {
                font-weight: bold;
                padding-right: 4px;
            }
        }

        >Button {
            width: fit-content;
            margin: 0;
        }
    }
    .legalSymbol {
        font-size: 0.8em;
        position: relative;
        top: -0.5em;
    }
    a, a.active, a.visited, a.hover {
        color: #2B88B4;
        font-weight: normal;
        text-decoration: underline;
    }
    .prodInfoText {
        line-height: 1.3;
        font-size: 11px;
        text-align: center;
    }
    .flexContainer {
        display: flex;
        justify-content: space-between;
        margin: 16px 0;
    }

    .center {
        text-align: center;
    }

    .bold {
        font-weight: bold;
    }

   
    .actionbuttons{
        display: flex;
        justify-content:center;
        .prescriberDashBtn {
            background-image: url(../../assets/images/btn_Prescriber_Dashboard.png);
            width: 198px;
            height: 34px;
            margin: 0px 3px;
        }
        span {
            display: inline-block;
            text-align: center;
            background-repeat: no-repeat;
            background-color: transparent;
            background-position: center center;
            vertical-align: middle;
        }
        .manageAcctBtn {
            background-image: url(../../assets/images/btn_Manage_My_Account.png);
            width: 198px;
            height: 34px;
            margin: 0px 3px;
        }
    }
   
  
}