@mixin iconStyle {
    display: inline-block;
    text-align: center;
    background-repeat: no-repeat;
    background-color: transparent;
    vertical-align: middle;
    background-position: center center;
}

.rems_program_lenalidomide {
    background-image: url(../../assets/images/img_RevAssist_transparent.png);
    @include iconStyle;
    width: 235px;
    height: 41px;
    padding-left: 2px;
}
.rems_program_pomalyst {
    @include iconStyle;
    width: 256px;
    height: 35px;
    padding-left: 2px;
    background-image: url(../../assets/images/Image_PomalidomideREMS_Transparent.png);
}
.rems_program_thalomid {
    @include iconStyle;
    width: 256px;
    height: 35px;
    padding-left: 2px;
    background-image: url(../../assets/images/img_STEPS_transparent.png);
}
.generalRemsProgram {
    @include iconStyle;
    width: 256px;
    height: 41px;
    padding-left: 2px;
}
.headerDivider{
    border: 2px solid;
}
.mainContainer {
    background-color: #ffffff;
    width: 763px;
    border-radius: 10px;
    padding: 0 25px;
    padding-bottom: 19px;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    color: #193878;
    box-shadow: 0px 3px 1px rgba(25, 56, 120, 0.3);
}

span.icon {
    @include iconStyle;
    background-image: url(../../assets/images/img_bms_logo.png);
    width: 256px;
    height: 35px;
    padding: 0 0 15px 0px;
}

.headerContainer {
    padding: 15px 0px 7px 0px ;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.headerDivider_rems_program_lenalidomide {
    border-bottom: 2px solid #fec110;
}
.headerDivider_rems_program_pomalyst {
    border-bottom: 2px solid #007482;
}
.headerDivider_rems_program_thalomid {
    border-bottom: 2px solid #f13945;
}

.contentContainer {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    margin-bottom: 12px;
    padding: 10px 30px 0px 0px ;
    div{
        color:#000000;
    }
}

.productHeader {
    color: #666;
    line-height: 18px;
    font-style: italic;
    font-weight: bold;
    padding: 7px 0;
    border-bottom: 1px solid #c0c0c0;
}

.note {
    padding: 5px;
    font-style: italic;
    text-align: end;
    padding-top: 10px;
    color: #000;
    span {
        color: #7b0305;
    }
}

.headerNote{
    color: #000;
}

.helpLink_postion {
    position: relative;
}

.containerPFD{
    display: grid;
    grid-template-columns: 0.7fr 1.3fr;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    color: #193878;
}

.iCD9TextCls {
    font-size: 12px;
    color: #ff0000 !important;
    margin-left: 34.8% ;
    padding: 6px;
}

.select_postion {
    position: absolute;
    top: 6.5px;
    left: 60.5%;
}

.buttonDivCls{
    padding-top: 10px;
    position: relative;
    left: 22px;
    padding-bottom: 10px;
}

.nxtBtn{
    position: relative;
    left: 20px;
    width: 113px;
    height: 55px;
    background-repeat: no-repeat;
    border: 0;
    cursor: pointer;
}

.nxtBtn_rems_program_lenalidomide_en{
    background-image: url(../../assets/images/program/lenalidomide/btn_Next.png);  
}
.nxtBtn_rems_program_lenalidomide_es{
    background-image: url(../../assets/images/program/lenalidomide/btn_Next_spanish.png);   
}
.nxtBtn_rems_program_pomalyst_en{
    background-image: url(../../assets/images/program/pomalyst/btn_Next.png);
}
.nxtBtn_rems_program_pomalyst_es{
    background-image: url(../../assets/images/program/pomalyst/btn_Next_spanish.png);
}
.nxtBtn_rems_program_thalomid_en{
    background-image: url(../../assets/images/program/thalomid/btn_Next.png);
}
.nxtBtn_rems_program_thalomid_es{
    background-image: url(../../assets/images/program/thalomid/btn_Next_spanish.png);
}


.prevBtn_rems_program_lenalidomide_en{
    background-image: url(../../assets/images/program/lenalidomide/btn_Previous.png);  
    width: 112px;
    margin-right: 7px ;
}
.prevBtn_rems_program_pomalyst_en{
    background-image: url(../../assets/images/program/pomalyst/btn_Previous_Pom.png);
    width: 112px;
    margin-right: 7px ;
}
.prevBtn_rems_program_thalomid_en{
    background-image: url(../../assets/images/program/thalomid/btn_Previous_thal.png);
    width: 112px;
    margin-right: 7px ;
    height: 54px;
}

.checkboxCont{
    display: flex;
}
 
.empty{
    flex: 2;
    margin-left: 3.4%;
}
 
.checkboxDiv {
    flex: 5;
    position: relative;
    input {
        margin: 10px 10px 20px 10px;
    }
    p {
        position: absolute;
        top: -5px;
        left: 34px;
 
    }
}



.extInput{
    position: relative;
    bottom: 54px;
    left: 215px;
}

.secButtonDivCls{
    margin-top: -10px;
    position: relative;
    left: 22px;
    padding-bottom: 10px;
}
.prevBtn{
    float:left;
    margin-right:10px;
}
.nextBnt{float:left;}
