.reportsPage{
    font-size: 12px;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    color: #193878;
    p{
        margin:0;
    }
    .textBold{
        font-weight: bold;
    }
    .sideMargins{
        margin: 0 20.5%;
       
        .pt10px{
            padding-top: 10px;
        }
        a{
            color: #193878; 
            text-decoration: underline;
            font-weight: bold;
        }
    }
}
