.patientSurvey {

    // common
    @mixin iconStyle {
        display: inline-block;
        text-align: center;
        background-repeat: no-repeat;
        background-color: transparent;
        background-position: center center;
        vertical-align: middle;
        border: none;
        cursor: pointer;
    }

    .legalSymbol {
        font-size: 0.8em;
        position: relative;
        top: -0.5em;
    }

    .actionButton {
        margin-top: 10px;
        float: left;
        margin-left: 5px;
    }

    .search {
        .requiredNote {
            padding: 5px;
            font-style: italic;
            float: right;
            margin-bottom: 5px;
        }

        .chooseProgramTitle {
            margin-left: 10px;
        }

        .required {
            color: #7B0305;
        }


        .formGroup {
            width: 100%;
            padding-left: 70px;
        }

        .flexContent {
            display: flex;
            align-items: center;
            width: 100%;
        }

        .en {
            .instruction1 {
                font-weight: bold;
                font-size: 12px;
                position: relative;
                right: 12px;
                flex: 1 1 auto;
                margin-right: 217px;
                min-width: 0;
                white-space: nowrap;
            }

            .instruction2 {
                font-weight: bold;
                font-size: 12px;
                position: relative;
                right: 33px;
            }
        }

        .es {
            .instruction1 {
                font-weight: bold;
                font-size: 12px;
                right: 10px;
                position: relative;
            }

            .instruction2 {
                font-weight: bold;
                font-size: 12px;
                position: relative;
                right: 10px;
            }
        }

        .surveyImage {
            padding-bottom: 6px;
        }

        .extraButton {
            @include iconStyle;
            background-image: url(../../assets/images/btn_Spanish_Patient_Survey.png);
            width: 178px;
            height: 72px;
            padding-right: 130px;
        }
    }

    .error {
        background-color: rgb(222, 233, 242);
        border: 2px solid #2b88b4;
        border-radius: 3px;
        padding: 0px 12px 4px 12px;
    }

    .contentContainer {
        background-color: #DEE9F2;
        border: 2px solid #5480A2;
        border-radius: 10px;
        margin: 10px 0px;
        width: 750px;
        margin-left: 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .link {
        text-align: center;
        align-content: center;
        margin-top: 40px;

        a,
        a.active,
        a.visited,
        a.hover {
            color: #2B88B4;
            font-weight: normal;            
        }
        a{
            u{
                color: #2B88B4;
            }
        }
    }

    .Lenalidomide {
        .revlimidRemsLoginImg {
            @include iconStyle;
            background-image: url(../../assets/images/img_RevlimidREMS_login.png);
            width: 178px;
            height: 72px;
        }
    }

    .Pomalidomide {
        .revlimidRemsLoginImg {
            @include iconStyle;
            background-image: url(../../assets/images/img_PomalidomideREMS_login.png);
            width: 178px;
            height: 72px;
        }
    }

    .Thalomid {
        .revlimidRemsLoginImg {
            @include iconStyle;
            background-image: url(../../assets/images/img_ThalomidREMS_login.png);
            width: 178px;
            height: 72px;
        }
    }

   

   .generalPrgmLogo{
        @include iconStyle;        
        width: 178px;
        height: 72px;
   }

    .buffer {
        padding: 2px;
        font-size: 8px;
        line-height: 8px;
    }

    .product {
        display: flex;
    }

    .bold {
        font-weight: bold;
    }

    .label {
        margin-right: 2px;
    }

    .bigPad_T {
        padding-top: 15px;
        align-items: center;
    }

    .bodyText {
        padding: 5px 15px;
    }

    .dynamicQues {
        .surveyText {
            font-weight: bold;
            font-size: 16px;
            padding: 10px 15px;
            color: #000;
        }

        .bodyContainer {
            display: grid;
            row-gap: 15px;
        }
        .dynamicButton{
            display: flex;
        }
        .restartSurveyBtn {               
            margin-left: auto;
            margin-right: 0;
        }
       
    }

    .restartSurvey {
        .bodyContainer {
            display: grid;
            row-gap: 15px;
        }

        .cofirmationbutton {
            display: flex;
            justify-content: center;
            margin-top: 15px;
            column-gap: 10px;
        }

        .warningIcon {
            background-image: url(../../assets/images/img_Warning_Exclamation.png);
            width: 28px;
            height: 28px;
            min-width: 28px;
        }

        .warningContiner {
            display: flex;
            column-gap: 10px;
        }

        .warningText {
            font-weight: bold;
        }

        .contineSubmissionMsg {
            font-weight: 100
        }

    }

    .restartSurvey {
        .bodyContainer {
            display: grid;
            row-gap: 15px;
        }

        .question {
            padding-top: 15px;
            padding-bottom: 10px;
            color: #000;
        }

        .surveyAnswer {
            background-color: #ccf;
            text-indent: 50px;
            padding: 5px;
            font-weight: bold;
        }

        .reviewButtons {
            display: flex;
            justify-content: center;
            margin-top: 15px;
            column-gap: 10px;
        }

    }


}
.ml6px{
    margin-left: 6px;
}