.container {
    display: grid;
    grid-template-columns: 0.7fr 1.3fr;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    color: #193878;
}
.pfdContainer{
    display: grid;
    grid-template-columns: 0.7fr 1.3fr;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    color: #193878;
    margin-top:3px;
}
.containerSurvey {
    display: grid;
    grid-template-columns: 6fr 3fr;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    color: #193878;
}
.containerPFD{
    display: grid;
    grid-template-columns: 0.8fr 1.3fr;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    color: #193878;
}
.containerAuth{
    display: grid;
    grid-template-columns: 0.4fr 1.3fr;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    color: #193878;
}
.label {
    text-align: right;
    margin: 5px;
}
.authlable{
    font-weight: 600;
}
.input {
    margin: 5px;
    max-width: 470px;
    word-wrap: break-word;
}
.mandatory {
    &::before {
        content: '* ';
        color: #7b0305;
    }
}
.helpTextSel {
    font-size: 11px;
    margin: 0;
    font-weight: normal;
}
.helpText {
    font-size: 11px;
    // max-width: 70%;
    margin: 0;
}

.helpTextPIN {
    font-size: 12px;
    // max-width: 70%;
    
}

.passwordContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    input::-ms-reveal,
    input::-ms-clear {
      display: none;
    }
}
.passwordContainer input[type='password'],
.passwordContainer input[type='text'] {
    box-sizing: border-box;
    width: 170px;
    padding-inline-end: 25px;
}

.passwordContainer input[name='securityAnswer'] {
    width: 170px;
    padding-inline-end: 25px;
}

.inputContainer input[name*='email'] + .helpText{
        text-decoration: underline;
        &::before{
            content: 'e.g.,';
            padding-right: 4px;
            text-decoration: none;
            display: inline-block;
        }
    }


   
.pswdIcon {
    background: none;
    font-size: 18px;
    border: unset;
    position: absolute;
    left: 30.6%;
    top: 6%;
    cursor: pointer;
}

.psdIc{
    background: none;
    font-size: 18px;
    border: unset;
    position: absolute;
    left: 51%;
    top: 6%;
    cursor: pointer;
}

.pswdImg {
    width: 17px;
    height: 17px;
}
.fontBold {
    font-weight: bold;
    margin: 5px;
    max-width: 470px;
    word-wrap: break-word;
}
.fontColor {
    font-weight: bold;
    margin: 5px;
    max-width: 470px;
    word-wrap: break-word;
    color: #000 !important;
}
