@mixin headerInfo {
    font-weight: bold;
    font-style: italic;
    padding: 0 15px;
    
}
.formHeader {
    display: flex;
    //align-items: flex-start;
    padding: 20px;
    padding-right: 40px;
}
.digital_wrapper {
    position: relative;
    margin-bottom: 50px;
}
.digital_content {
    margin-left: -2%;
}
.abc{
    display:flex;
}

.heading_info {
    // @include headerInfo();
    display: flex;
    //column-gap: 5px;
     font-weight: bold;
     font-style: italic;
    // padding: 0 0px 0 15px;
    width: 565px;
   
}

.digital_sign {
    @include headerInfo();
    font-style: normal;
}


.pdfInfoLink {
    text-decoration: underline;
    font-size: 11px;
    font-weight: normal;
    cursor: pointer;
    //margin-right: 25px;
  // width: 159px;
}

.stopIcon {
    width: 36px;
    height: 36px;
    margin-right: 12px;
}
.checkbox_wrapper {
    display: inline-flex;
    margin-right: 22px;
}
.checkbox_content {
    width: 151% !important;
    text-align: right;
    margin-right: 14px;
}
.contact_information {
    padding-left: 12px;
    float: left;
    width: 80%;
}
.disclaimer_style {
    padding: '0px !important';
    font-weight: bold;
}
ul li {
    list-style: disc;
    padding-left: 0px !important;
}
.signed_by {
    display: flex;
    align-items: center;
    justify-content: center;
}
.signed_by_paragraph {
    font-style: italic;
    text-decoration: underline;
}
.submit_print_button {
    margin: 52px 0 10px 10px;
    display: flex;
    column-gap: 15px;
}

.adobeClickHereToOpenBtn {
    background-image: url('./../../assets/images/btn_Adobe_Click_Here_to_Open.png');
    width: 159px;
    height: 34px;
    margin-top: 5px;
    margin-left: 50px;
}

.getAdobe{
    background-image: url('../../assets//images/btn_Get_Adobe_Reader.png');
    width: 158px;
    height: 39px;
    margin-top: 20px;
   // padding:0 150px 20px 0;
   
}

.signupHeader{
    display: flex;
    justify-content: space-between;
    padding-right: 38px;
    padding-bottom: 20px;
    
}

.loader {
    display: flex;
    justify-content: center;
    height: 7vh;
    float:left;
    position: relative;
    left:10px;
}
.buttonStyles_signOn{
    float:left;
}