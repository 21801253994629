.bannerContent {
    border: 2px solid #5480a2;
    border-radius: 1%;
    padding: 15px;
    padding-top: 8px;
    height: auto;
    background: -webkit-gradient(linear, left top, left bottom, from(#dee9f2), to(#e5f2fd));
}

.mainContent {
    border: 2px solid #5480a2;
    padding: 12px 15px;
    height: auto;
    background-color: white;
}

.instructions_bold {
    font-weight: bold;
    padding: 10px 0px;
}

.warning {
    width: 28px;
    height: 28px;
    vertical-align: middle;
    padding: 0 8px;
}

.activeProductsErrorContainer{
    display: flex;
    flex-direction: column;
    justify-content:space-around;

}
.activeProductsSubContainer{
    margin: 2% auto 1% !important;
}