.patientTable {
    :global {
        background-color: #ffffff;
        font-size: 11px;
        border: 2px solid #7690ab;
    }
    tbody {
        tr:nth-child(odd) {
            background: #fff;
        }

        tr:nth-child(even) {
            background:#F1F5F8;
        }
        tr > th,
        tr > td {
            padding: 8px;
            border-left: 1px solid #7690ab;
        }
    }
}

.upArrow {
    background-image: url('../../../assets/images/btn_arrow_up.png');
    width: 7px;
    height: 4px;
}

.downArrow {
    background-image: url('../../../assets/images/btn_arrow_down.png');
    width: 7px;
    height: 4px;
}

.icon {
    display: inline-block;
    text-align: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;
    vertical-align: middle;
}

.sortControl {
    border-left: none !important;
    padding: 5px 2px 0 0;
    white-space: nowrap;
    text-align: right;
}

.patientDetails{
    td{
        color: #4b6075;
        font-size: 11px;
        font-weight: normal;
    }
    td>a{
        color: #2B88B4;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 11px;
        img{
            padding-left: 4px;
        }
    }
}

.noRecordsFound{
    color: #4B6075;
    padding: 8px;
    font-size: 11px;
}