.fgtPswdContainer {
    .note {
        padding: 5px;
        font-style: italic;
        text-align: end;
        padding-top: 19px;
    }

    .required {
        color: #7b0305;
    }

    .contentContainer {
        background-color: #dee9f2;
        border: 2px solid #5480a2;
        border-radius: 5px;
        margin-bottom: 12px;
        padding-bottom: 12px;
    }

    .formHeader {
        padding: 15px;
        padding-bottom: 5px;
        font-weight: bold;
    }
    .container {
        display: grid;
        grid-template-columns: 0.7fr 1.3fr;
        font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
        font-size: 12px;
        color: #193878;
    }
    .label {
        text-align: right;
        margin: 5px;
    }
    .input {
        margin: 5px;
        max-width: 470px;
        word-wrap: break-word;
    }
    .mandatory {
        display: inline-block;
        margin-top: 1%;

        &::before {
            content: '* ';
            color: #7b0305;
        }
    }
    .passwordContainer {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        input::-ms-reveal,
        input::-ms-clear {
        display: none;
        }
    }
    .passwordContainer input[type='password'],
    .passwordContainer input[type='text'] {
        box-sizing: border-box;
        width: 170px;
        padding-inline-end: 25px;
    }
    
    .passwordContainer input[name='securityAnswer'] {
        width: 170px;
        padding-inline-end: 25px;
    }
    .pswdIcon {
        background: none;
        font-size: 18px;
        border: unset;
        position: absolute;
        left: 30.6%;
        top: 6%;
        cursor: pointer;
    }
    
    .pswdImg {
        width: 17px;
        height: 17px;
    }
}
