.bannerContent {
    border: 2px solid #5480a2;
    border-radius: 5px;
    padding: 0 10px;
    margin-bottom: 12px;
    height: auto;
    background: -webkit-gradient(linear, left top, left bottom, from(#dee9f2), to(#e5f2fd));
}

.instructions_bold {
    font-weight: bold;
    padding: 10px 0px;
}

.instructions {
    padding-top: 10px;
    padding-bottom: 10px;
}
