@mixin image_commonstyles {
    height: 50px;
    background-repeat: no-repeat;
    background-position: top right;
}
.rems_program_lenalidomide {
    @include image_commonstyles;
    background-image: url(../../assets/images/img_RevAssist_container_small.png);
}
.rems_program_pomalyst {
    @include image_commonstyles;
    background-image: url(../../assets/images/img_POMRems_container_new.png);
}
.rems_program_thalomid {
    @include image_commonstyles;
    background-image: url(../../assets/images/img_STEPS_container.png);
}


.pinInput{
    position: relative;
}

.helpLink{
    position: relative;
    left: 450px;
    top: -50px;
    font-weight: bold;
    color: #2B88B4;
    cursor: pointer;

}
.helpLinkNoInput{
    position: relative;
    left: 365px;
    top: -47px;
    font-weight: bold;
    color: #2B88B4;
    cursor: pointer;

}
.generalProgramLogo {
    @include image_commonstyles;
}
.headerDivider {
    border-bottom: 2px solid;
    padding-top: 5px;
}