@mixin bold {
    font-weight: bold;
}
@mixin flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    border: 0;
    position: relative;
    position: fixed;
}
.modalContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.06);
}
.mainContainer {
    @include flex;
    width: 400px;
    text-align: center;
    color: #193878;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    background: -webkit-gradient(linear, left top, left bottom, from(#aecee8), to(#f5faff));
    border: 2px solid #7690ab;
    padding: 0;
    top: 120px;
    border-radius: 8px;
}
.whiteInfoContainer {
    width: 100%;
}

.header {
    border-bottom: 1px solid #c0c0c0;
    padding: 10px 10px 10px 10px;
    @include bold;
}
.btnContainer {
    @include flex;
    justify-content: center;
    padding: 0 10px 10px 10px;
}
.bigPad {
    padding: 15px;

    ul {
        padding-left: 15px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin: 0 0 0 2px;

        li {
            list-style: disc;
            padding-top: 3px;
            padding-left: 5px;
            margin-top: 10px;
        }
    }
}
