@mixin alignCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin iconStyle {
    display: inline-block;
    text-align: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;
    vertical-align: middle;
}
@mixin image_commonstyles {
    height: 50px;
    background-repeat: no-repeat;
    background-position: top right;
}

.headerRemsProgram {
    @include iconStyle;
    width: 256px;
    height: 35px;
    padding-left: 2px;
}

.mainContainer {
    background-color: #ffffff;    
    width: 805px;
    border-radius: 10px;
    padding: 0 5px;
    padding-bottom: 19px;
    /* padding-top: 9px; */
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    color: #193878;
    box-shadow: 0px 3px 1px rgba(25, 56, 120, 0.3);
}


.bold {
    font-weight: bold;
}

.header {
    padding-bottom: 8px;
}

span.icon {
    @include iconStyle;
    background-image: url(../../assets/images/img_bms_logo.png);
    width: 256px;
    height: 35px;
    padding: 0 0 15px 0px;
}

.title {
    font-size: 18px;
    font-weight: bold;
    padding-top:10px;
    align-self: flex-start;
    color:#193878;
}

.headerContainer {
    padding-top: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 5px;
}

.programLogo {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.productButtonLogo {
    padding: 6px 0;
    @include iconStyle;
    background-image: url(../../assets/images/img_RevAssist_container.png);
    width: 156px;
    height: 49px;
    align-self: flex-end;
}
.headerNote{
    color: #000;
}
.note {
    padding: 5px;
    font-style: italic;
    text-align: end;
    padding-top: 10px;
    color: #000;
}
.headerDivider_rems_program_lenalidomide{
    border-bottom: 2px solid #fec110;
}
.headerDivider_rems_program_pomalyst{
    border-bottom: 2px solid #007482;
}
.headerDivider_rems_program_thalomid{
    border-bottom: 2px solid #F13945;
}
.required {
    color: #7b0305;
}

.contentContainer {
    display: grid;
    grid-template-columns: 1.5fr 1.5fr;
    padding: 10px;
   padding-top: 25px;
        div{
        color:#000000;
    }
}

.ppafIcon{
    box-sizing: border-box;
    width:400px;
    height:130px;
    display: inline-block;
    text-align: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;
    vertical-align: middle;
    cursor: pointer;
    border: 0;
}

.custom_btn{
    background-size: cover;
}
.btnWithImage{
    position: relative;
    margin: 12px 10px 20px 6px;
    .innerImage{
        position: absolute;
        z-index: 9;
        width: 65px;
        background-size: contain !important;
        height: 65px;
        background-repeat: no-repeat !important;
        left: 14px;
        top: 18px;
        &.prod_Lenalidomide{
            background: url(../../assets/images/program/lenalidomide/patient_reg.png);
        }
        &.prod_Thalomid{
            background: url(../../assets/images/program/thalomid/patient_reg.png);
        }
        &.prod_Pomalyst{
            background: url(../../assets/images/program/pomalyst/patient_reg_pomalyst.png);
        }
        &.prod_Pomalidomide{
            background: url(../../assets/images/program/pomalyst/patient_reg.png);
        }
        &.prodSubmittedPPAF_Lenalidomide{
            background: url(../../assets/images/program/lenalidomide/icon_saved_blue.png);
        }
        &.prodSubmittedPPAF_Thalomid{
            background: url(../../assets/images/program/thalomid/icon_saved_red.png);
        }
        &.prodSubmittedPPAF_Pomalyst{
            background: url(../../assets/images/program/pomalyst/icon_saved_green_pomalyst.png);
        }
        &.prodSubmittedPPAF_Pomalidomide{
            background: url(../../assets/images/program/pomalyst/icon_saved_green.png);
        }
        &.prodSurvey_Lenalidomide{
            background: url(../../assets/images/program/lenalidomide/icon_survey_blue.png);
        }
        &.prodSurvey_Thalomid{
            background: url(../../assets/images/program/thalomid/icon_survey_red.png);
        }
        &.prodSurvey_Pomalyst{
            background: url(../../assets/images/program/pomalyst/icon_survey_green_pomalyst.png);
        }
        &.prodSurvey_Pomalidomide{
            background: url(../../assets/images/program/pomalyst/icon_survey_green.png);
        }
        &.prodPrescription_Lenalidomide{
            background: url(../../assets/images/program/lenalidomide/icon_prescription_blue.png);
        }
        &.prodPrescription_Thalomid{
            background: url(../../assets/images/program/thalomid/icon_prescription_red.png);
        }
        &.prodPrescription_Pomalyst{
            background: url(../../assets/images/program/pomalyst/icon_prescription_green_pomalyst.png);
        }
        &.prodPrescription_Pomalidomide{
            background: url(../../assets/images/program/pomalyst/icon_prescription_green.png);
        }
    }
    &.smallIcons{
        .innerImage{
            width: 54px;
            height: 54px;
        }
       
    }
    &.noMarP{ 
        p{
            margin-bottom: 0 !important;
        }
    }
}

.btn_PatientReg_rems_program_lenalidomide{
    background-image: url(../../assets/images/program/lenalidomide/btn_New_PPAF_Patient_Registration.png);
}
.btn_PatientReg_es_rems_program_lenalidomide{
    background-image: url(../../assets/images/program/lenalidomide/btn_New_PPAF_Patient_Registration_es.png);
}
.btn_SubmittedPPAF_rems_program_lenalidomide{
    background-image: url(../../assets/images/program/lenalidomide/btn_Work_With_Saved_Submitted_PPAF_Forms.png);
}
.btn_SubmittedPPAF_es_rems_program_lenalidomide{
    background-image: url(../../assets/images/program/lenalidomide/btn_Work_With_Saved_Submitted_PPAF_Forms_es.png);
}
.btn_PrescriberSurvey_rems_program_lenalidomide{
    background-image: url(../../assets/images/program/lenalidomide/btn_Prescriber_Survey_Menu_Button.png);
}
.btn_PrescriberSurvey_es_rems_program_lenalidomide{
    background-image: url(../../assets/images/program/lenalidomide/btn_Prescriber_Survey_Menu_Button_es.png);
}
.btn_StandardPrescription_rems_program_lenalidomide{
    background-image: url(../../assets/images/program/lenalidomide/btn_Standard_Prescription_Form.png);
}
.btn_VAPresxription_rems_program_lenalidomide{
    background-image: url(../../assets/images/program/lenalidomide/btn_VA_Prescription_Form.png);
}


.btn_PatientReg_rems_program_pomalyst{
    background-image: url(../../assets/images/program/pomalyst/btn_New_PPAF_Patient_Registration.png);
}
.btn_PatientReg_es_rems_program_pomalyst{
    background-image: url(../../assets/images/program/pomalyst/btn_New_PPAF_Patient_Registration_es.png);
}
.btn_SubmittedPPAF_rems_program_pomalyst{
    background-image: url(../../assets/images/program/pomalyst/btn_Work_With_Saved_Submitted_PPAF_Forms.png);
}
.btn_SubmittedPPAF_es_rems_program_pomalyst{
    background-image: url(../../assets/images/program/pomalyst/btn_Work_With_Saved_Submitted_PPAF_Forms_es.png);
}
.btn_PrescriberSurvey_rems_program_pomalyst{
    background-image: url(../../assets/images/program/pomalyst/btn_Prescriber_Survey_Menu_Button.png);
}
.btn_PrescriberSurvey_es_rems_program_pomalyst{
    background-image: url(../../assets/images/program/pomalyst/btn_Prescriber_Survey_Menu_Button_es.png);
}
.btn_StandardPrescription_rems_program_pomalyst{
    background-image: url(../../assets/images/program/pomalyst/btn_Standard_Prescription_Form.png);
}
.btn_VAPresxription_rems_program_pomalyst{
    background-image: url(../../assets/images/program/pomalyst/btn_VA_Prescription_Form.png);
}

.btn_PatientReg_rems_program_thalomid{
    background-image: url(../../assets/images/program/thalomid/btn_New_PPAF_Patient_Registration.png);
}
.btn_PatientReg_es_rems_program_thalomid{
    background-image: url(../../assets/images/program/thalomid/btn_New_PPAF_Patient_Registration_es.png);
}
.btn_SubmittedPPAF_rems_program_thalomid{
    background-image: url(../../assets/images/program/thalomid/btn_Work_With_Saved_Submitted_PPAF_Forms.png);
}
.btn_SubmittedPPAF_es_rems_program_thalomid{
    background-image: url(../../assets/images/program/thalomid/btn_Work_With_Saved_Submitted_PPAF_Forms_es.png);
}
.btn_PrescriberSurvey_rems_program_thalomid{
    background-image: url(../../assets/images/program/thalomid/btn_Prescriber_Survey_Menu_Button.png);
}
.btn_PrescriberSurvey_es_rems_program_thalomid{
    background-image: url(../../assets/images/program/thalomid/btn_Prescriber_Survey_Menu_Button_es.png);
}
.btn_StandardPrescription_rems_program_thalomid{
    background-image: url(../../assets/images/program/thalomid/btn_Standard_Prescription_Form.png);
}
.btn_VAPresxription_rems_program_thalomid{
    background-image: url(../../assets/images/program/thalomid/btn_VA_Prescription_Form.png);
}
  

.formHeader {
    padding: 15px;
    padding-bottom: 5px;
    font-weight: bold;
}

.formContainer {
    display: grid;
    grid-template-columns: 0.7fr 1.3fr;
    place-items: start;

    div:nth-child(odd) {
        place-self: flex-end;
    }
}

.inputContainer {
    padding: 5px;
}

.formSubHeader {
    padding: 5px 15px 15px 15px;
}

.productHeader{
    color: #666;
    line-height: 18px;
    font-style: italic;
    font-weight: bold;
    padding: 7px 0;
    border-bottom: 1px solid #C0C0C0;
}

