.layout {
    background-image: url('../../assets/images/img_global_bg.png');
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 10px;
}
.container {
    background-color: #7ba5c4;
    background-image: url('../../assets/images/prescriber_login_bg.png');
    background-repeat: no-repeat;
    background-position: top left;
    width: 815px;
    border-radius: 10px;
    margin-top: 8px;
    position: relative;
    color: #193878;
    text-align: justify;
    box-shadow: 0 4px 2px 0px #668dae;
    padding-bottom: 20px;
}
.contentPadding {
    padding: 0 50px 0 100px;
}
.textPadding {
    padding-left: 16px;
    font-size: 12px;
    > p:first-child {
        font-size: 16px;
        font-weight: bold;
        padding-top: 55px;
        color: #19388f;
    }
}
.programContainer {
    border: 3px solid white;
    border-radius: 5px;
    width: 191px;
    padding: 10px;
    padding-bottom: 16px;
    text-align: center;
    font-size: 10px;
    background: -webkit-gradient(linear, left top, left bottom, from(#aecee8), to(#f5faff));
    display: flex;
    flex-direction: column;
    align-items: center;
    > img {
        width: 178px;
        height: 72px;
        cursor: pointer;
        margin-bottom: 2px;
    }
    > p {
        margin: 0;
        width: 181px;
        > span {
            color: #2b88b4;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}
.taskContainer {
    border: 3px solid white;
    border-radius: 5px;
    width: 171px;
    padding: 10px 20px 5px 20px;
    font-size: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    background: -webkit-gradient(linear, left top, left bottom, from(#aecee8), to(#f5faff));
    > p {
        margin: 0;
        > span {
            font-weight: bold;
            padding-right: 4px;
        }
    }
    > Button {
        width: fit-content;
        margin: 0;
        margin-top: 4px;
    }
}
.flexContainer {
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
}
.survey {
    text-align: center;
    margin-top: 20px;
}
.link {
    background-color: #20427e;
    border: 2px solid #20427e;
    padding: 4px;
    color: #fff;
    margin: 2px 0px;
    cursor: pointer;
    border-radius: 4px;
    text-decoration: none;
    font-size: 12px;
}
.disableLink {
    pointer-events: none;
}
