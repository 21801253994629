.findAPatient {
    :global {
        font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
        .headingString > p {
            font-size: 12px;
            padding: 0px 5px;
            margin-top: 0;
            color: #193878;
        }
        .patientTable {
            display: flex;
            justify-content: center;
        }
    }
}

.findPatientPrintBtn {
    :global {
        cursor: pointer;
        color: #2b88b4;
        font-weight: normal;
        text-decoration: none;
        font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
        font-size: 12px;
        position: absolute;
        right: -8px;
        top: -60px;
        b{
            font-size: 16px;
        }
    }
}
