@mixin bold {
    font-weight: bold;
}
@mixin flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    border: 0;
    position: relative;
    position: fixed;
}
.modalContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.06);
}
.mainContainer {
    @include flex;
    width: 400px;
    text-align: center;
    color: #193878;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    background: -webkit-gradient(linear, left top, left bottom, from(#aecee8), to(#f5faff));
    border: 2px solid #7690ab;
    padding: 0;
    top: 120px;
    border-radius: 8px;
}
.whiteInfoContainer {
    width: 100%;
}

.header {
    border-bottom: 1px solid #c0c0c0;
    padding: 10px 10px 10px 10px;
    @include bold;
}
.btnContainer {
    @include flex;
    justify-content: center;
    padding: 0 10px 10px 10px;
}
.bigPad {
    padding: 10px;

    ul {
        padding-left: 15px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin: 0 0 0 2px;

        li {
            list-style: disc;
            padding-top: 3px;
            padding-left: 5px;
            margin-top: 10px;
        }
    }
}
.closeBtn {
    position: absolute;
    background-color: #193878;
    color: #ffff;
    border: none;
    border-radius: 3px;
    top: 10px;
    right: 10px;
}
.locationbox{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background: white;
    border: 1px solid #193878;

    .expandedRow{
        background-color: #dee9f2;
        margin: 0 1.5%;
        border: 2px solid #5480a2;
        padding:1%;
        padding-bottom: 2%;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        @media print{
            border: none;
        }
        &_inputs{
            //width:55%;
            color: rgb(75, 96, 117);
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            @media print{
                row-gap: 5px;
            }
    
            &_item{
                display: flex;
                 margin-left: 40px;
               
            }
            &_label{
                font-size: 12px;
                font-weight: 400;
                width: 15%;
                @media print{
                    font-weight: bold;
                }
            }
            &_value{
                font-size: 12px;
                font-weight: 700;
                text-align: left;
                line-break: anywhere;
                @media print{
                    font-weight: 400;
                }
            }
            &_colon{
                font-weight: 400 !important;
                margin-right: 2px;
            }
        }
    
    }
}
.title{
   text-align: left;
   margin-left: 20px;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
}
.subtitle{
    text-align: left;
     font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
     font-size: 12px;
     font-weight: bold;
     text-align: center;
 }