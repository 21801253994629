@mixin bold {
    font-weight: bold;
}

@mixin flex {
    display: flex;
    align-items: center;
    justify-content: center;
}


.modalContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 100;
    border: solid;
    color: #193878;    
}

.mainContainer {
    @include flex;
    width: 340px;
    text-align: center;
    color: #193878;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    background-color: #FFFFFF;
	border: 2px solid #5c709c;   
    top: 120px;
    height: 120px;
}

.whiteInfoContainer {
    width: 100%;
}

.header {
    border-bottom: 1px solid #c0c0c0;
    padding: 10px 10px 10px 10px;
    @include bold;
}

.btnContainer {
    @include flex;
    justify-content: center;
    padding: 0 10px 10px 10px;
}

.content {
    padding: 15px;
    @include bold;
}