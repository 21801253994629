.helpContent {
    :global{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        color: #193878;
        font-size: 12px;
        main{
            text-align: justify;
            margin-top: -5px;
        }
        footer{
            text-align: center;
            color: #476392;
            font-size: 12px;
            padding-top: 16px;
            margin-bottom: -20px;
        }
    }
}

.actionItemMain {
    margin-top: -10px;
    padding-right: 10px;
}

.secListDiv {
    margin: 15px 0 10px 15px;

    p {
        margin: 20px 0px 0px 0px;
        font-weight: bold;
    }
}

.tableContainer {
    margin: 15px 30px;
    font-size: 12px;

    table {
        border: 1px solid #000;
        padding: 5px;
        text-align: left;
        width: 95%;
        border-collapse: collapse;

        th {
            min-width: 110px;
            border: 1px solid #000;
            padding: 5px;
            background-color: #d3d3d3;
            color: #000;

            &:first-child {
                min-width: 95px;
            }

            &:nth-child(3) {
                width: 172px;
            }
        }

        tr {
            &:nth-child(even) {
                background-color: #fff;
            }

            &:nth-child(even) {
                background-color: #eee;
            }

            td {
                border: 1px solid #000 !important;
                padding: 5px !important;
            }
        }
    }
}
.textUnderline{
    text-decoration: underline;
}