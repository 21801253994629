.mainContainer {
    background-color: gradient(linear, left top, left bottom, from(#e5f2fc), to(#7ba4c4));
    background-image: linear-gradient(to bottom, #e5f2fc, #7ba4c4);
    display: flex;
    flex-direction: column;
    width: 763px;
    border-radius: 10px;
    padding: 0 26px;
    padding-bottom: 19px;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    color: #193878;
    box-shadow: 0px 3px 1px rgba(25, 56, 120, 0.3);
    
}

.dispense{
    width:795px !important;
    padding:0 10px 19px !important;
    @media print{
        box-shadow:none;
        font-family: 'Times New Roman';
        color: rgb(0, 0, 0) !important;
    }
}

.eccpContainer{
    height: 320px;
}

@mixin iconStyle {
    display: inline-block;
    text-align: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;
    vertical-align: middle;
}

.header {
    padding-bottom: 8px;
    @media print{
        display: none;
    }
}

span.icon {
    @include iconStyle;
    background-image: url(../../assets/images/img_bms_logo.png);
    width: 256px;
    height: 35px;
    padding: 10px 0px;
    padding-top: 15px;
}
.img_icon {
    @include iconStyle;
    background-image: url(../../assets/images/img_ThalomidREMS_login.png);
    width: 187px;
    height: 40px;
    padding: 15px 0px;
    align-self: flex-end;
}

.rems_program_thalomid {
    background-image: url(../../assets/images/img_STEPS_container.png);
    
}

.rems_program_lenalidomide {
    background-image: url(../../assets/images/img_RevAssist_container.png);
}

.rems_program_pomalyst {
    background-image: url(../../assets/images/img_POMRems_container_new.png);
}
.title {
    font-size: 18px;
    font-weight: bold;
    padding: 9px 0px;
    padding-bottom: 12px;
    align-self: flex-end;
   

    
}
.pharmacytitle {
    font-size: 15px;
    font-weight: bold;
    padding: 25px 0px;
    padding-bottom: 12px;
    align-self: flex-end;
}

.headerContainer {
    padding-top: 9px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.programLogo {
    display: flex;
    flex-direction: column;
}

.productButtonLogo {
    padding: 6px 0;
    @include iconStyle;
    background-image: url(../../assets/images/img_RevAssist_container.png);
    width: 156px;
    height: 49px;
    align-self: flex-end;
}

.note {
    padding: 5px;
    font-style: italic;
    text-align: end;
}

.required {
    color: #7b0305;
}

.contentContainer {
    background-color: #dee9f2;
    border: 2px solid #5480a2;
    border-radius: 5px;
    margin-bottom: 12px;
    padding-bottom: 12px;
}

.formHeader {
    padding: 15px;
    padding-bottom: 5px;
    font-weight: bold;
}

.formContainer {
    display: grid;
    grid-template-columns: 0.7fr 1.3fr;
    place-items: start;

    div:nth-child(odd) {
        place-self: flex-end;
    }
}

.inputContainer {
    padding: 5px;
}
.flexContain{
    display: flex;
}
.formSubHeader {
    padding: 5px 15px 15px 15px;
}


.buttonStyles{
    width: 175px !important;
}
.loader {
    display: flex;
    justify-content: center;
   /*  height: 15vh; */
}
