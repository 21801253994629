// application core styles
@import 'styles/index.scss';

body {
    box-sizing: border-box;
    margin: 0;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
}

@media screen and (min-width:899px) and (max-width: 979px) {
    select option {
        zoom: 120% !important;
    }
    .ant-select-dropdown{
        zoom:94% !important
    }

}

@media screen and (min-width:800px) and (max-width: 898px) {
    select option {
        zoom: 130% !important;
    }
    .ant-select-dropdown{
        zoom:85% !important
    }
}

@media screen and (min-width:700px) and (max-width: 799px) {
    select option {
        zoom: 140% !important;
    }
    .ant-select-dropdown{
        zoom:78% !important
    }
}

@media screen and (min-width:600px) and (max-width: 699px) {
    select option {
        zoom: 150% !important;
    }
    .ant-select-dropdown{
        zoom:65% !important
    }
}

@media screen and (min-width:500px) and (max-width: 599px) {
    select option {
        zoom: 200% !important;
    }
    .ant-select-dropdown{
        zoom:55% !important
    }
}

@media screen and (min-width:400px) and (max-width: 499px) {
    select option {
        zoom: 260% !important;
    }
    .ant-select-dropdown{
        zoom:46% !important
    }
}
@media screen and (min-width:400px) and (max-width: 430px) {
    .ant-select-dropdown{
        zoom:44% !important
    }
}

@media screen and (min-width:300px) and (max-width: 399px) {
    select option {
        zoom: 350% !important;
    }
    .ant-select-dropdown{
        zoom:40% !important
    }
}

@media screen and (max-width: 299px) {
    select option {
        zoom: 450% !important;
    }
    .ant-select-dropdown{
        zoom:31% !important
    }
}