.pagination {
    :global {
        display: flex;
        gap: 10px;
        transform: translate(66px, 4px);
        margin-bottom: 8px;
        li {
            list-style: none !important;
            a{
                font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
                font-size: 12px;
                color: #2b88b4;
                span:nth-child(1){
                    margin-left: -5px;
                    padding-left: 2px;
                    padding-right: 2px;
                }
                span:nth-child(2){
                    color: #2b88b4;
                    font-weight: normal;
                }
            }
        }
    }
}

.active a {
    span:nth-child(1){
        color: #000 !important;
        font-weight: bold;
    }
}

.rightArrow{
    background-image: url('../../../assets/images/btn_arrow_right.png');
    width: 4px;
    height: 7px
}

.leftArrow {
    background-image: url('../../../assets//images/btn_arrow_left.png');
    width: 4px;
    height: 7px
}

.icon{
    display: inline-block;
    text-align: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;
    vertical-align: middle;
}