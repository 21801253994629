.reportTable {
    background-color: #dee9f2;
    border: 2px solid #5480a2;
    font-size: 11px;
    line-height: normal;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;

    &.emptyTable {
        padding: 5px 0px;
        border-radius: 5px;

        tr {
            background: transparent !important;

            td {
                border: 0;
                text-align: center;
                font-weight: 400;
                color: rgb(25, 56, 120);
                font-size: 12px;
            }
        }
    }

    tbody {
        tr:nth-child(odd) {
            background: #f1f5f8;
        }

        tr:nth-child(even),
        tr:nth-child(2),
        tr:nth-child(1) {
            background: #fff;
        }

        tr > th,
        tr > td {
            padding: 8px;
            border-left: 1px solid #7690ab;
            text-align: left;
        }

        tr > th {
            padding: 2px 0 2px 8px;
            vertical-align: top;
        }
    }
}

.reportHistoricalTable {
    overflow-x: hidden;
    .reportTable {
        tbody > tr > th {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(4) {
                width: 12%;
            }
            &:nth-child(3){
                    max-width: 100px;
            }
            &:nth-child(5){
                label{
                    max-width: 78px;
                    padding-right: 8px;
                }
            }
            padding-right: 0px !important;
        }
    }
}

.upArrow {
    background-image: url('../../../assets/images/btn_arrow_up.png');
    width: 7px;
    height: 4px;
}

.downArrow {
    background-image: url('../../../assets/images/btn_arrow_down.png');
    width: 7px;
    height: 4px;
}

.icon {
    display: inline-block;
    text-align: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;
    vertical-align: middle;
}

.sortControl {
    border-left: none !important;
    padding: 0 2px 0 0;
    white-space: nowrap;
    text-align: right;
    display: inline-block;
    float: right;
}

.patientDetails {
    td {
        color: #4b6075;
        font-size: 11px;
        font-weight: normal;
    }

    td > a {
        color: #2b88b4;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 11px;

        img {
            padding-left: 4px;
        }
        .underline {
            text-decoration: underline;
            max-width: 170px;
            display: block;
            text-transform: none;
        }
    }

    td:nth-child(3) {
        text-transform: capitalize;
    }
}

.noRecordsFound {
    color: #4b6075;
    padding: 8px;
    font-size: 11px;
}

.authorization_column {
    padding: 2px 8px !important;
    border-bottom: 1px solid #7690ab;
    text-align: center !important;
}

.nestedContent {
    color: red !important;
}

.thContainer {
    display: flex;
    justify-content: space-between;
}

.contentReportsTable {
    border: 2px solid #5480a2;
    border-radius: 5px;
    padding: 3px;
    height: 170px;
    overflow-y: scroll;
    background: -webkit-gradient(linear, left top, left bottom, from(#c9e4f8), to(#e5f2fd));
}

.firstRow {
    background-color: #fff;
}

.pr8px {
    padding-right: 8px !important;
}
.colWidth {
    width: 20%;
}

.popupDiv {
    position: absolute;
    top: -40%;
    left: 25%;
}

.reportsTableContainer {
    position: relative;
    .exprttoExlBtn {
        position: absolute;
        top: -60px;
        right: 0;
        background: transparent;
        border: 0;
        outline: none;
        cursor: pointer;
        img {
            width: 37px;
        }
        p {
            font-size: 10px;
            font-weight: bold;
            margin: 0;
        }
    }
}

.heading {
    display: none;
    & + br {
        display: none;
    }
}

.date {
    display: none;
}

.logo {
    display: none;
}
.location {
    display: none;
}
@media print {
    @page {
        margin-top: 80px;
        margin-bottom: 35px;
    }
    .exprttoExlBtn {
        display: none;
    }
    .reportTable {
        width: 725px;
        margin-top: 10px;
        border: 2px solid white;
        border-right: 2px solid black;
        border-top: 2px solid black;
        background-color: #fff;
        font-size: 15px;
        line-height: normal;
        font-family: BMS-Humanity;

        &.emptyTable {
            padding: 5px 0px;
            border-radius: 5px;

            tr {
                background: transparent !important;

                td {
                    border: 0;
                    text-align: center;
                    font-weight: 400;
                    color: rgb(0, 0, 0);
                    font-size: 10px;
                }
            }
        }

        tbody {
            tr:nth-child(odd) {
                background: #ffff;
            }

            tr:nth-child(even),
            tr:nth-child(2),
            tr:nth-child(1) {
                background: #fff;
            }

            tr > th,
            tr > td {
                padding: 4px;
                border-left: 2px solid #000;
                text-align: left;
                border-bottom: 2px solid black;
            }

            tr > th {
                padding: 2px 0px 15px 8px;
                vertical-align: top;
            }
        }
    }

    .upArrow {
        display: none;
    }

    .downArrow {
        display: none;
    }

    .heading {
        display: block;
        margin-bottom: 4px;
        font-size: 18px;
        text-align: center;
        font-weight: 500;
        color: #000;
        font-family: BMS-Humanity;
        & + br {
            display: block;
        }
    }

    .date {
        display: block;
        text-align: center;
        margin-top: -22px;
        color: #000;
        font-family: BMS-Humanity;
    }

    .icon {
        display: none;
    }

    .sortControl {
        display: none;
    }

    .patientDetails {
        td {
            color: #000;
            font-size: 9px;
            font-weight: normal;
        }

        td > a {
            color: #000;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 9px;

            img {
                display: none;
            }
        }

        td:nth-child(3) {
            text-transform: capitalize;
        }
    }

    .authorization_column {
        padding: 8px 4px !important;
        border-bottom: 2px solid #000 !important;
        text-align: center !important;
    }

    .thContainer {
        display: flex;
        justify-content: space-between;
    }

    .contentReportsTable {
        border: 2px solid #fff;
        border-radius: 5px;
        padding: 3px;
        height: auto;
        background: #fff;
        overflow-y: hidden;
        margin-left: 35px;
    }

    .firstRow {
        background-color: #000;
    }

    .pr8px {
        padding-right: 4px !important;
    }

    .nestedContent {
        color: #000 !important;
    }

    .rightArrow {
        display: none;
    }
    .logo {
        display: block;
        text-align: center;
        font-family: BMS-Humanity;
    }
    .location {
        margin-bottom: 15px;
        margin-left: 30px;
        font-size: 9px;
        display: flex;
        gap: 100px;
    }
}
