.container{
    width:759px;
    min-height:290px;
    background-color: #fff;
    border-radius: 10px;
    padding:12px 28px;
}

.headerContainer{
    margin-bottom: 8px;
}

@mixin iconStyle {
    display: inline-block;
    text-align: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;
    vertical-align: middle;
}


.productHeader{
    color: #666;
    line-height: 18px;
    font-style: italic;
    font-weight: bold;
    font-size: 12px;
    padding-bottom: 5px;

}

.contentDivider{
    border-top: 1px solid #C0C0C0;
    padding-top: 15px;
}

.mainContainer{
    display: flex;
    flex-direction: column;
    width:731px;
    min-height:158px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    padding: 15px 10px 5px 10px;
    color:#000 !important;
    margin-bottom: 12px;
}

.btn_group{
    display: flex;
    padding-top: 20px;
    padding-bottom: 10px;

    .btn{
        border: none;
        cursor: pointer;
        height:49px;
        text-align: center;
        background-repeat: no-repeat;
        background-color: transparent;
        background-position: center center;
        vertical-align: middle;
       
    }
}


.programlogo {
    @include iconStyle;
    width: 250px;
    height: 41px;
    padding: 5px 0px;
    align-self: flex-end;
}

/* styles based on program*/

.rems_program_thalomid {
    .programlogo {
        background-image: url(../../assets/images/img_STEPS_transparent.png);
    }
    .headerDivider {
        border-bottom: 2px solid #f13945;
    }
    
}

.rems_program_lenalidomide {
    .programlogo {
        background-image: url(../../assets/images/img_RevAssist_transparent.png);
    }

    .headerDivider {
        border-bottom: 2px solid #fec110;
    }
   
}

.rems_program_pomalyst {
    .programlogo {
        background-image: url(../../assets/images/Image_PomalidomideREMS_Transparent.png);
    }

    .headerDivider {
        border-bottom: 2px solid #007482;
    }
   
}

.risk_warning{
    font-size: 12px;
}
.generalProgramLogo {
    @include iconStyle;
    width: 256px;
    height: 40px;
    padding-left: 2px;
    padding-bottom: 2px;
}
.headerDivider {
    border-bottom: 2px solid;
    padding-top: 5px;
}