.headerDivider {
    border-bottom: 2px solid #fec110;
    padding: 2px;
    font-size: 8px;
    line-height: 8px;
    clear: both;
}
.generalHeaderDivider{
    border-bottom: 2px solid;
    padding: 2px;
    font-size: 8px;
    line-height: 8px;
    clear: both;
}

.headerContainer {
    color: #193878;
    padding-bottom: 8px;
}

.contentDivider {
    border-top: 1px solid #C0C0C0;
    padding-top: 15px;
}

.bodyContainer {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    color: #000;
}

.buffer {
    padding: 2px;
    font-size: 8px;
    line-height: 8px;
}

.bodyContent {
    padding: 15px;

    p {
        font-weight: bold;

        a {
            text-decoration: underline;
            font-weight: bold;
            color: #2B88B4;
        }
    }
}

.underline {
    text-decoration: underline;
}

.bold {
    font-weight: bold;
}

.productHeader {
    color: #666;
    line-height: 18px;
    font-style: italic;
    font-weight: bold;
    padding-bottom: 5px;
}

.mainContainer {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    width: 763px;
    border-radius: 10px;
    padding: 0 26px;
    padding-bottom: 19px;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    color: #193878;
    box-shadow: 0px 3px 1px rgba(25, 56, 120, 0.3);
}

@mixin iconStyle {
    display: inline-block;
    text-align: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;
    vertical-align: middle;
}

.actionbutton {
    text-align: center;
}

.processPPAFOnlineBtn {
    @include iconStyle;
    width: 265px;
    height: 49px;
    border: none;
    cursor: pointer;
}

.createFaxiblePPAFBtn {
    @include iconStyle;
    width: 265px;
    height: 49px;
    border: none;
    cursor: pointer;
}

.programlogo {
    @include iconStyle;
    width: 250px;
    height: 41px;
    padding: 5px 0px;
    align-self: flex-end;
}
.generalProgramlogo {
    @include iconStyle;
    width: 250px;
    height: 41px;
    padding: 5px 0px;
    align-self: flex-end;
}
/* styles based on program*/

.thalomid {
    .programlogo {
        background-image: url(../../assets/images/img_STEPS_transparent.png);
    }

    .headerDivider {
        border-bottom: 2px solid #f13945;
    }
}

.lenalidomide {
    .programlogo {
        background-image: url(../../assets/images/img_RevAssist_transparent.png);
    }

    .headerDivider {
        border-bottom: 2px solid #fec110;
    }
}

.pomalidomide {
    .programlogo {
        background-image: url(../../assets/images/Image_PomalidomideREMS_Transparent.png);
    }

    .headerDivider {
        border-bottom: 2px solid #007482;
    }
}

.titleButtonContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.spanishButton{
    @include iconStyle;
    width: 130px;
    height: 52px;
    margin-top: 10px;
    border: none;
}
.spanishLenButton {
    @include iconStyle;
    background-image: url(../../assets/images/buttonProgram/es/btn_Spanish_len.png);
    width: 130px;
    height: 52px;
    margin-top: 10px;
    border: none;
}

.spanishPomButton {
    @include iconStyle;
    background-image: url(../../assets/images/buttonProgram/es/btn_Spanish_pom.png);
    width: 130px;
    height: 52px;
    margin-top: 10px;
    border: none;
}

.spanishThalButton {
    @include iconStyle;
    background-image: url(../../assets/images/buttonProgram/es/btn_Spanish_thal.png);
    width: 130px;
    height: 52px;
    margin-top: 10px;
    border: none;
}