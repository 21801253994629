.layout {
    width: 100vw;
    height: 100vh;
    background-image: url('../../assets/images/img_global_bg.png');
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    color: #193878;
}

.container {
    width: 760px;
    margin: 10px;
    margin-top: 18px;
    padding: 10px 15px;
    background-color: #ffffff;
    border: 2px solid #7690ab;
}

.header {
    border-bottom: 2px solid #fec110;
}

span.icon {
    display: inline-block;
    text-align: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;
    vertical-align: middle;
    background-image: url(../../assets/images/img_RevAssist_transparent.png);
    width: 256px;
    height: 35px;
    padding: 15px 0px 10px;
}

.title {
    font-size: 18px;
    font-weight: bold;
    padding: 15px 0px 10px;
}

.headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer {
    color: #476392;
    text-align: center;
    padding: 10px;
}

.helpContainer {
    padding: 10px;
}

.bold {
    font-weight: bold;
}

ul {
    padding-left: 15px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 0 0 0 2px;

    li {
        list-style: disc;
        padding-top: 3px;
        padding-left: 5px;
    }
}
