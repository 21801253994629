.fgtPswdContainer {
    .note {
        padding: 5px;
        font-style: italic;
        text-align: end;
        padding-top: 19px;
    }

    .required {
        color: #7b0305;
    }

    .contentContainer {
        background-color: #dee9f2;
        border: 2px solid #5480a2;
        border-radius: 5px;
        margin-bottom: 12px;
        padding-bottom: 12px;
    }

    .formHeader {
        padding: 15px;
        padding-bottom: 5px;
        font-weight: bold;
    }
    .container {
        display: grid;
        grid-template-columns: 0.7fr 1.3fr;
        font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
        font-size: 12px;
        color: #193878;
    }
    .label {
        text-align: right;
        margin: 5px;
    }

    .text {
        font-weight: bold;
        display: 'flex';
        align-items: 'center';
        margin-left: '5px';
        margin-top: '5px';
    }
}
