.dashboard {

    .contentContainer {
        background-color: #dee9f2;
        border: 2px solid #5480a2;
        border-radius: 5px;
        border-top-left-radius: 0px;
        padding: 15px 10px;
        background: -webkit-gradient(linear, left top, left bottom, from(#aecee8), to(#f5faff));
    }

    .prescriberCalender {
        padding: 15px 5px !important;
    }

    .contentAuthContainer {
        background-color: #dee9f2;
        border: 2px solid #5480a2;
        border-radius: 5px;
        padding: 15px 10px;
        input {
            width: 62%;
        }
    }
    .buttonWrapper {
        margin-top: 3px;
        margin-left: 1px;
    }
    .authLabel {
        color: #193878;
        font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
        font-size: 12px;
        margin-left: 10px;
        margin-bottom: 8px;
        position: relative;
        span{
            position: absolute;
            color: #2B88B4;
            right: 12px;
            cursor: pointer;
            b{
                font-size: 16px;
            }
        }
    }
    .tabsStyling {
        :global{
            margin-top: 20px;
            .ant-tabs-tab-active{
                background-color: #cae4f8 !important;
                border-bottom-width: 0.1px !important;
                border-bottom-color: #e3ebf0 !important;
                .ant-tabs-tab-btn{
                    color : #28394E;
                    line-height: 27px;
                    font-size: 14px;
                    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
                    font-weight: normal;
                }
            } 
            .ant-tabs-tab{
                background-color: #5480A2;
                margin-right: 3px;
                color : #fff;
                font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
                padding: 1px 9px !important;
                font-size: 14px;
                border-color: #5480a2;
                border-width: 2px;
            }
            .ant-tabs-nav{
                margin: 0 0 0 0 !important;
            }
        }
    }
}