.prescriberDashboardCalender {
    :global {
        border: 2px solid #5480a2;
        padding: 7px 9px;
        font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
        .top {
            display: flex;
            justify-content: flex-end;
            i {
                color: #193878;
                font-size: 12px;
                span {
                    color: #7b0305;
                }
            }
        }
        .bottom {
            display: flex;
            padding-bottom: 15px;
            padding-top: 5px;
            .prescriberSelectOptions,
            .statusesSelectOptions {
                display: flex;
                align-items: flex-start;
                .labelTexts p {
                    margin: 0;
                    color: #193878;
                    font-size: 12px;
                    font-weight: bold;
                }
                .associatedPrescriberSelection,
                .statusRefrenceData {
                    background-color: #ffffff;
                    border: 2px solid #aeb3b7;
                    width: 200px;
                    height: 175px;
                    overflow-x: hidden;
                    resize: none;
                    div {
                        padding: 4px;
                        label {
                            font-size: 11px;
                        }
                    }
                }
                .associatedPrescriberSelection {
                    width: 175px;
                }
            }
            .prescriberSelectOptions > .labelTexts  {
                display: flex;
                padding-right: 6px;
                padding-left: 4px;
            }
            .statusesSelectOptions > .labelTexts > p {
                padding-right: 6px;
                padding-left: 4px;
            }
            .viewCalenderBtn {
                align-self: flex-end;
                padding-left: 3px;
                transform: translateY(3px);
                cursor: pointer;
            }
        }
    }
}

.required {
    color: #7b0305;
    font-size: 12px;
}

.noRecordsFound{
    display: flex;
    justify-content: center;
    color: #193878;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.contentContainer{
    background-color: #DEE9F2;
    border: 2px solid #5480A2;
    margin: 20px;
    padding-top: 10px;
    border-radius: 5px;
    padding-bottom: 10px;
}
.noRecords{
    text-align: center;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    color:#193878;
    font-size: 12px;
}
.calenderPrintBtn{
    :global {
        cursor: pointer;
        color: #2b88b4;
        font-weight: normal;
        text-decoration: none;
        font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
        font-size: 12px;
        position: absolute;
        right: -8px;
        top: -60px;
        b{
            font-size: 16px;
        }
    }
}
.errorContainer{
    position:absolute;
    top:5px;
}
