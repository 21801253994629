@mixin iconStyle {
    display: inline-block;
    text-align: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;
    vertical-align: middle;
}

@mixin btnStyles {
    width: 180px;
    border: none;
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    height: 45px;
    margin: 10px 20px;
}

.mainContainer {
    background-color: #ffffff;
    width: 763px;
    border-radius: 10px;
    padding: 0 26px;
    padding-bottom: 19px;
    padding-top: 9px;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    color: #193878;
    box-shadow: 0px 3px 1px rgba(25, 56, 120, 0.3);
}

.headerContainer {
    padding-top: 9px;
}
.headerNote {
    color: #000;
    margin: 10px 25px 0 0;
}
.note {
    padding: 5px;
    font-style: italic;
    text-align: end;
    padding-top: 10px;
    color: #000;
    span {
        color: #7b0305;
    }
}
.required {
    color: #7b0305;
}
.riskError {
    font-size: 0.9em;
    background: #ffffcc;
    border: 1px solid #ffe640;
    margin: 10px 30px 5px 30px;
    padding: 10px;
}

.contentContainer {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    margin-bottom: 12px;
    div {
        color: #000000;
    }
}

.helpLink_postion {
    position: relative;
}
.helpLink {
    position: absolute;
    left: 444px;
    top: 8px;
    color: #2b88b4;
    cursor: pointer;
    text-decoration: underline;
}

.productHeader {
    color: #666;
    line-height: 18px;
    font-style: italic;
    font-weight: bold;
    padding: 7px 0;
    border-bottom: 1px solid #c0c0c0;
}

.programlogo {
    @include iconStyle;
    width: 250px;
    height: 41px;
    padding: 5px 0px;
    align-self: flex-end;
}

/* styles based on program*/

.rems_program_thalomid {
    .programlogo {
        background-image: url(../../assets/images/img_STEPS_transparent.png);
    }
    .headerDivider {
        border-bottom: 2px solid #f13945;
    }

    .btn_Save {
        background-image: url(../../assets/images/program/thalomid/btn_Patient_Search_Thalomid.png);
        @include btnStyles;
        // width: 93px;
        // height: 93px;


    }
    .btn_Save_es {
        background-image: url(../../assets/images/program/es/thalomid/btn_Patient_Search_Spanish.png);
        @include btnStyles;
        // width: 93px;
    }
}

.rems_program_lenalidomide {
    .programlogo {
        background-image: url(../../assets/images/img_RevAssist_transparent.png);
    }

    .headerDivider {
        border-bottom: 2px solid #fec110;
    }

    .btn_Save {
        background-image: url(../../assets/images/program/lenalidomide/btn_Patient_Search_Lenalidomide.png);
        @include btnStyles;
    }
    .btn_Save_es {
        background-image: url(../../assets/images/program/es/lenalidomide/btn_Patient_Search_Spanish.png);
        @include btnStyles;
        // width: 93px;
    }
}

.rems_program_pomalyst {
    .programlogo {
        background-image: url(../../assets/images/Image_PomalidomideREMS_Transparent.png);
    }

    .headerDivider {
        border-bottom: 2px solid #007482;
    }
    .btn_Save {
        background-image: url(../../assets/images/program/pomalyst/btn_Patient_Search_Pomalyst.png);
        @include btnStyles;
    }
    .btn_Save_es {
        background-image: url(../../assets/images/program/es/pomalyst/btn_Patient_Search_Spanish.png);
        @include btnStyles;
        // width: 93px;
    }
}

.btnMargins{
    margin: 10px 0 14px 23px;
}
.generalProgramLogo {
    @include iconStyle;
    width: 256px;
    height: 40px;
    padding-left: 2px;
    padding-bottom: 2px;
}
.headerDivider {
    border-bottom: 2px solid;
    padding-top: 5px;
}
