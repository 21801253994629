@mixin bold {
    font-weight: bold;
}

@mixin flexRowCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flexRowEnd {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.timeoutModalContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.06);

    .mainContainer {
        @include flexRowCenter;
        width: 400px;
        text-align: center;
        color: #193878;
        font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
        font-size: 12px;
        border: 2px solid #7690ab;
        padding: 0;
        top: 120px;
    }
    .whiteInfoContainer {
        width: 100%;
    }
    .btnContainer {
        @include flexRowCenter;
        justify-content: center;
        padding: 0 10px 10px 10px;
    }
    .bigPad {
        padding: 15px;

        ul {
            padding-left: 15px;
            padding-top: 0px;
            padding-bottom: 0px;
            margin: 0 0 0 2px;

            li {
                list-style: disc;
                padding-top: 3px;
                padding-left: 5px;
                margin-top: 10px;
            }
        }
    }
}
.timeoutCont {
    font-weight: bold;
    font-size: 12px;
    text-align: left;
    padding: 0 10px;
}
.ctnBtn {
    @include flexRowEnd();
    margin-bottom: 10px;
    padding: 0 10px;
}
