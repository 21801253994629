.container {
    display: flex;
    height: 100vh;
    background-color: rgb(11, 11, 11);
}

.thumbNail {
    max-width: 22%;
    padding: 1%;
    padding-top: 4%;
    overflow-y: auto;
    background-color: rgb(45, 45, 45);
    &_header {
        color: white;
    }
    &_section {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    &_container {
        display: flex;
        flex-direction: column;
        gap: 50px;
        padding: 10px 15px;
        background-color: rgb(11, 11, 11);
    }

    &_content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &_image {
        height: 300px;
        width: 200px;
        &:hover {
            border: 5px solid rgb(55, 0, 255);
        }
    }

    &_image_selected {
        height: 300px;
        width: 200px;
        border: 5px solid rgb(55, 0, 255);
    }

    &_index {
        color: #ffffff;
        margin-top: 5px;
    }
}

.document {
    display: flex;
    flex: 1;
    flex-direction: column;
    &_navs {
        color: #ffffff;
        background-color: rgb(45, 45, 45);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 2% 0 1%;

        &_input {
            display: flex;
            gap: 10px;
        }
    }

    &_content {
        display: flex;
        overflow-y: auto;
        padding: 10px;
        flex-direction: column;
        align-items: center;
    }

    &_page {
        margin-bottom: 20px;
    }
}

.icon {
    height: 20px;
    width: 20px;
    color: #ffffff;
    cursor: pointer;
}

.tooltipClass {
    div {
        color: #000 !important;
    }
}

.loader {
    display: flex;
    justify-content: center;
    height: 60vh;
}
