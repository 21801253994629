@mixin image_commonstyles {
    width: 235px;
    height: 41px;
    background-repeat: no-repeat;
    background-position: top left;
}

@mixin iconStyle {
    display: inline-block;
    text-align: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;
    vertical-align: middle;
}

.subTextAuthentication {
    padding: 10px 50px;
    font-size: 11px;
    text-align: left;
}

.topnotes {
    padding: 5px 15px;
}

.productDetailContainer {
    background-color: #f0f0f0;
    border: 1px solid silver;
    color: #000;
    font-weight: bold;
    padding: 5px;
}

.buffer {
    padding: 2px;
    font-size: 8px;
    line-height: 8px;
}

.checkboxArragement {
    margin: 8px 10px;
}

.labelArrgement {
    padding: 5px;
    td{
        padding: 1px;
    }
}

.argreementFormat {
    display: flex;
}

.bold {
    font-weight: bold;
}

.bodyContent {
    padding: 0 14px;
    line-height: 1.45em;
}

.patient_wrapper {
   border-radius: 10px;
    padding: 10px 26px;
    background: #fff;
    width: 763px;
}

.pgm_name {
    color: #666;
    font-size: 12px;
    padding: 8px 0 5px 0px;
    border-bottom: 1px solid #c0c0c0;
    font-weight: bold;
    line-height: 18px;
}

.note {
    font-style: italic;
    text-align: end;
    padding-top: 13px;
    font-size: 12px;
    color: #000;
}

.required {
    color: #7b0305;
}

.product_wrapper {
    border-radius: 10px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    font-size: 12px;
}

@mixin checkBoxValue {
    //margin-top: 13px !important;
    padding: 0px 8px;
    line-height: 1.5em !important;
}

.checkbox_wrapper {
    position: relative;
}

.flex1 {
    @include checkBoxValue;
    position: absolute;
    top: 0px;
    left: 42px;
}

.lenalidomide {
    @include image_commonstyles;
    /* background-image: url(../../assets/images/img_RevAssist_transparent.png); */
}

.bms {
    @include image_commonstyles;
    background-image: url(../../assets/images/img_bms_logo_Resize1.png);
}

.new_image {
    @include image_commonstyles;
    /* background-image: url(../../assets/images/Image_PomalidomideREMS_Transparent.png); */
    height: 74px !important;
}

.pom_logo_image {
    @include image_commonstyles;
    width: 361px !important;
    background-image: url(../../assets/images/img_POMRems_white_1.png);
}

.pom_image_wrapper {
    display: flex;
    margin: 29px;
}
.pom_image_wrapper_pom {
    display: flex;
    margin: 29px;
    justify-content: right
}
.pom_image_wrapper_pom_old {
    display: flex;
    margin: 29px;
    justify-content: space-between;
    .new_image{
        width: 100%;
        background-size: 85%;
        background-position: center;
    }
}
.thal_logo_image {
    @include image_commonstyles;
    width: 361px !important;
   /*  background-image: url(../../assets/images/img_STEPS_white.png); */
}

.thal_white_image {
    @include image_commonstyles;
    height: 52px !important;
    /* background-image: url(../../assets/images/img_Thalomid_white1.png); */
}

.authorizationSubDetails {
    font-weight: bold;
    padding: 2px 5px;
}

.treatmentSubcontent {
    padding: 0px 6px;
}

.checkbox_wrapper {
    align-items: baseline;
    display: grid;
    grid-template-columns: 0.7fr 1.3fr;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
}

.checkbox_content1 {
    text-align: right;
    padding-right: 10px;
}

.checkbox_content2 {
    padding-left: 5px;
}

.required {
    color: #7b0305;
}

.button_wrapper {
    display: flex;
    justify-content: center;
    margin: 12px;
    cursor: pointer;
}

.patient_data {
    margin: 10px;
}

.SavePrintContainer {
    width: 50%;
}

.AdobeInformation {
    width: 50%;
}

.headerDivider {
    padding: 2px;
    font-size: 8px;
    line-height: 8px;
    clear: both;
}
.engLabel{
    text-align: center;
    font-weight: bold;
}
.programlogo {
    @include iconStyle;
    width: 250px;
    height: 41px;
    padding: 5px 0px;
    align-self: flex-end;
}

.productSignAndContinue {
    @include iconStyle;
    width: 200px;
    height: 49px;
    border: none;
    cursor: pointer;
}

.saveBtn {
    @include iconStyle;
    width: 112px;
    height: 49px;
    border: none;
    cursor: pointer;
}

.printBtn {
    @include iconStyle;
    width: 112px;
    height: 49px;
    border: none;
    cursor: pointer;
}

.getAdobeReaderBtn {
    @include iconStyle;
    background-image: url(../../assets/images/btn_Get_Adobe_Reader.png);
    width: 158px;
    height: 39px;
}

.actionbutton {
    margin-top: 40px;
    display: flex;
}

.legalSymbol {
    font-size: 0.8em;
    position: relative;
    top: -0.5em;
}

.checkbox_content3 {
    padding: 5px;
    width: 100%;
    display: flex;
}

.rems_program_thalomid {
    .en {
        .programlogo {
            background-image: url(../../assets/images/img_STEPS_transparent.png);
        }
    
        .headerDivider {
            border-bottom: 2px solid #f13945;
        }    
       
        .productSignAndContinue {
            background-image: url(../../assets/images/program/thalomid/btn_Sign_and_Submit.png);
        }
    
        .saveBtn {
            background-image: url(../../assets/images/program/thalomid/btn_Save.png);
        }
    
        .printBtn {
            background-image: url(../../assets/images/program/thalomid/btn_Print.png);
        }
    } 
    .es {
        .programlogo {
            background-image: url(../../assets/images/img_STEPS_transparent.png);
        }
    
        .headerDivider {
            border-bottom: 2px solid #f13945;
        }    
      
        .productSignAndContinue {
            background-image: url(../../assets/images/program/es/thalomid/btn_Sign_and_Submit.png);
        }
    
        .saveBtn {
            background-image: url(../../assets/images/program/es/thalomid/btn_Save.png);
        }
    
        .printBtn {
            background-image: url(../../assets/images/program/es/thalomid/btn_Print.png);
        }
    }
}

.rems_program_lenalidomide {
    .en {
        .programlogo {
            background-image: url(../../assets/images/img_RevAssist_transparent.png);
        }

        .headerDivider {
            border-bottom: 2px solid #fec110;
        } 
        .productSignAndContinue {
            background-image: url(../../assets/images/program/lenalidomide/btn_Sign_and_Submit.png);
        }

        .saveBtn {
            background-image: url(../../assets/images/program/lenalidomide/btn_Save.png);
        }

        .printBtn {
            background-image: url(../../assets/images/program/lenalidomide/btn_Print.png);
        }
    }
    .es {
        .programlogo {
            background-image: url(../../assets/images/img_RevAssist_transparent.png);
        }

        .headerDivider {
            border-bottom: 2px solid #fec110;
        }     
        .productSignAndContinue {
            background-image: url(../../assets/images/program/es/lenalidomide/btn_Sign_and_Submit.png);
        }

        .saveBtn {
            background-image: url(../../assets/images/program/es/lenalidomide/btn_Save.png);
        }

        .printBtn {
            background-image: url(../../assets/images/program/es/lenalidomide/btn_Print.png);
        }
    }
}

.rems_program_pomalyst {
    .en {
        .programlogo {
            background-image: url(../../assets/images/Image_PomalidomideREMS_Transparent.png);
        }
    
        .headerDivider {
            border-bottom: 2px solid #007482;
        }
    
      
        .productSignAndContinue {
            background-image: url(../../assets/images/program/pomalyst/btn_Sign_and_Submit.png);
        }
    
        .saveBtn {
            background-image: url(../../assets/images/program/pomalyst/btn_Save.png);
        }
    
        .printBtn {
            background-image: url(../../assets/images/program/pomalyst/btn_Print.png);
        }
    } .es {
        .programlogo {
            background-image: url(../../assets/images/Image_PomalidomideREMS_Transparent.png);
        }
    
        .headerDivider {
            border-bottom: 2px solid #007482;
        } 
        .productSignAndContinue {
            background-image: url(../../assets/images/program/es/pomalyst/btn_Sign_and_Submit.png);
        }
    
        .saveBtn {
            background-image: url(../../assets/images/program/es/pomalyst/btn_Save.png);
        }
    
        .printBtn {
            background-image: url(../../assets/images/program/es/pomalyst/btn_Print.png);
        }
    }
}
.signed_by {
    text-align: center;
    font-weight: bold;
    text-decoration: underline;
    font-style: italic;
    font-size: 12px;
}
.generalProgramLogo {
    @include iconStyle;
    width: 256px;
    height: 40px;
    padding-left: 2px;
    padding-bottom: 2px;
}
.generalHeaderDivider {
    border-bottom: 2px solid;
    padding-top: 5px;
}
.loader {
    display: flex;
    justify-content: center;
    height: 7vh;
    
}

