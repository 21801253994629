.container{
    width:759px;
    min-height:305.33px;
    background-color: #fff;
    border-radius: 10px;
    padding:12px 28px;
}

.headerContainer{
    margin-bottom: 8px;
}

@mixin iconStyle {
    display: inline-block;
    text-align: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;
    vertical-align: middle;
}


.productHeader{
    color: #666;
    line-height: 18px;
    font-style: italic;
    font-weight: bold;
    font-size: 12px;
    padding-bottom: 5px;

}

.contentDivider{
    border-top: 1px solid #C0C0C0;
    padding-top: 15px;
}

.mainContainer{
    display: flex;
    flex-direction: column;
    width:735px;
    min-height:182.67px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    padding: 15px 10px 5px 10px;
    color:#000 !important;

}

.btn_group{
    display: flex;
    column-gap: 10px;
    padding-top: 10px;

    .btn{
        border: none;
        cursor: pointer;
        //background-size: cover;
        height:49px;
        //display: inline-block;
        text-align: center;
        background-repeat: no-repeat;
        background-color: transparent;
        background-position: center center;
        vertical-align: middle;
       
    }



}
.btn_Continue_len{
    background-image: url(../../assets/images/program/lenalidomide/btn_Continue.png);
    width:170px;
    
}
.btn_Continue_pom{
    background-image: url(../../assets/images/program/pomalyst/btn_Continue.png);
    width:170px;
    
}
.btn_Continue_thal{
    background-image: url(../../assets/images/program/thalomid/btn_Continue.png);
    width:170px;
    
}
.btn_Continue_len_es{
    background-image: url(../../assets/images/program/es/lenalidomide/btn_Continue.png);
    width:170px;
    
}
.btn_Continue_pom_es{
    background-image: url(../../assets/images/program/es/pomalyst/btn_Continue.png);
    width:170px;
    
}
.btn_Continue_thal_es{
    background-image: url(../../assets/images/program/es/thalomid/btn_Continue.png);
    width:170px;
    
}
.btn_Save_pom{
    background-image: url(../../assets/images/program/pomalyst/btn_View_PPAF.png);
    width:170px;
    
}

.btn_Save_len{
    background-image: url(../../assets/images/program/lenalidomide/btn_View_PPAF.png);
    width:170px;
    
}

.btn_Save_thal{
    background-image: url(../../assets/images/program/thalomid/btn_View_PPAF.png);
    width:170px;
    
}

.btn_Revise_len{
    background-image: url(../../assets/images/program/lenalidomide/btn_Search_New_Patient.png);
    width:265px;
    

}

.btn_Revise_thal{
    background-image: url(../../assets/images/program/thalomid/btn_Search_New_Patient.png);
    width:265px;
    

}

.btn_Revise_pom{
    background-image: url(../../assets/images/program/pomalyst/btn_Search_New_Patient.png);
    width:265px;
    

}

.btn_Save_pom_es{
    background-image: url(../../assets/images/program/es/pomalyst/btn_View_PPAF.png);
    width:170px;
    
}

.btn_Save_len_es{
    background-image: url(../../assets/images/program/es/lenalidomide/btn_View_PPAF.png);
    width:170px;
    
}

.btn_Save_thal_es{
    background-image: url(../../assets/images/program/es/thalomid/btn_View_PPAF.png);
    width:170px;
    
}

.btn_Revise_len_es{
    background-image: url(../../assets/images/program/es/lenalidomide/btn_Search_New_Patient.png);
    width:265px;
    

}

.btn_Revise_thal_es{
    background-image: url(../../assets/images/program/es/thalomid/btn_Search_New_Patient.png);
    width:265px;
   
    

}

.btn_Revise_pom_es{
    background-image: url(../../assets/images/program/es/pomalyst/btn_Search_New_Patient.png);
    width:265px;
    
    

}

.programlogo {
    @include iconStyle;
    width: 250px;
    height: 41px;
    padding: 5px 0px;
    align-self: flex-end;
}

/* styles based on program*/

.rems_program_thalomid {
    .programlogo {
        background-image: url(../../assets/images/img_STEPS_transparent.png);
    }
    .headerDivider {
        border-bottom: 2px solid #f13945;
    }
    
}

.rems_program_lenalidomide {
    .programlogo {
        background-image: url(../../assets/images/img_RevAssist_transparent.png);
    }

    .headerDivider {
        border-bottom: 2px solid #fec110;
    }
   
}

.rems_program_pomalyst {
    .programlogo {
        background-image: url(../../assets/images/Image_PomalidomideREMS_Transparent.png);
    }

    .headerDivider {
        border-bottom: 2px solid #007482;
    }
   
}

.risk_warning{
    font-size: 12px;
}
.btn_Write_pres_len {
    background-image: url(../../assets/images/program/lenalidomide/btn_Write_Prescription.png);
    min-width: 193px;
}
.btn_Write_pres_thal {
    background-image: url(../../assets/images/program/thalomid/btn_Write_Prescription.png);
    min-width: 193px;
}
.btn_Write_pres_pom {
    background-image: url(../../assets/images/program/pomalyst/btn_Write_Prescription.png);
    min-width: 193px;
}
.generalProgramLogo {
    @include iconStyle;
    width: 256px;
    height: 40px;
    padding-left: 2px;
    padding-bottom: 2px;
}
.headerDivider {
    border-bottom: 2px solid;
    padding-top: 5px;
}