.container{
    width:759px;
    // min-height:830.33px;
    background-color: #fff;
    border-radius: 10px;
    padding:12px 28px;
    margin-top: 10px;
}

.error{
    width:759px;
    min-height:240.33px;
    background-color: #fff;
    border-radius: 10px;
    padding:12px 28px;
    margin-top: 10px;
}

.headerContainer{
    margin-left: -7px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 8px;
}

@mixin iconStyle {
    display: inline-block;
    text-align: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;
    vertical-align: middle;
}


.productHeader{
    color: #666;
    line-height: 18px;
    font-style: italic;
    font-weight: bold;
    font-size: 12px;
    padding-bottom: 5px;
    padding-top: 7px;

}
.infoHeader{
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
}

.contentDivider{
    border-top: 1px solid #C0C0C0;
    padding-top: 15px;
}

.mainContainer{
    display: flex;
    flex-direction: column;
    height: max-content;
    width:735px;
    min-height:623.3px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    padding: 15px 10px 5px 10px;
    color:#000 !important;
    position: relative;

}

.errorContainer {
    display: flex;
    flex-direction: column;
    height: max-content;
    width:735px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    padding: 15px 10px 5px 10px;
    color:#000 !important;
    position: relative;
    min-height:80.3px;
}

.react-datepicker__view-calendar-icon input {
    padding: 0px;
  }
.contentContainer{
    background-color: #f0f0f0;
    display: flex;
    justify-content: space-around;
    // padding: 10px;
}
// .contentContainer .infoInput{
//     padding-bottom: 10px !important;
// }
.infoInput{
    // padding-top: 100px !important;
}
.infoColumn1{
    width: 50%;
}
.prescriberInput_es{
    padding-bottom: 25px;
    
}
.prescriberInput{
    padding-bottom: 10px;
    // padding-left: -15px;
}
.patientNumber_es{
    margin-left: -10px;
    width: 105%;
}
.patientNumber{
    padding-bottom: 10px;
}
.infoColumn2{
    margin-top: 35px;
    width: 50%;
}
.infoColumn1_len,.infoColumn1_pom,.infoColumn1_thal{
    margin-left: -15px;
    
}
.infoColumn2_len,.infoColumn2_pom,.infoColumn2_thal{
    // margin-top: 35px;
    position: absolute;
    left: 400px;
    top: 116px;
    // margin-right: 10px;
}
.infoColumn1_len_es,.infoColumn1_pom_es,.infoColumn1_thal_es{
    margin-left: 30px;
}
.infoColumn2_len_es,.infoColumn2_pom_es,.infoColumn2_thal_es{
    margin-top: 20px;
}
.surveyQuestions{
    
}
.questions{
    position: relative;
    display: flex;
    font-size: 12px;
    justify-content: space-between;
}
.questionValues{
    display: flex;
    margin-left: 10px;
    width: 50%;
    // padding-bottom: 25px;
    // padding-left: 15px;
    word-wrap: break-word;
}
.calenderValues{
    width: 45%;
    margin-top: 0px;
}

.btnValues{
    position: absolute;
    margin-top: 13px;
    right: 5px;
    // margin-left: -153px;
    
}
.customDatePicker {
    padding: 0px !important;
}
ol,li{
    padding-top: 3px;
}
.btnFooter{
    padding-bottom: 50px;
    padding-left: 50px;
}

.btn_group{
    
    display: flex;
    column-gap: 35px;
    // padding-top: 10px;

    .btn{
        border: none;
        cursor: pointer;
        //background-size: cover;
        height:49px;
        //display: inline-block;
        text-align: center;
        background-repeat: no-repeat;
        background-color: transparent;
        background-position: center center;
        vertical-align: middle;
       
    }



}
// next buttons
.btnSmall{
    color: #FFF !important;
    border: none;
    font-size: 12px;
    padding: 2px 8px;
    background: linear-gradient(180deg, #9CF 0%, #9CF 13.54%, #9CF 26.04%, #9CF 52.6%, #9CF 74.48%, #9CF 88.54%, #9CF 100%);
    &.btn_Next_small_pom{
        background: rgb(0, 89, 120);
    }
    &.thalprod{
        background: #ef3a42;
    }
}

.btn_Next_small_tealBtn{
    background: rgb(0, 89, 120);
}

.btn_Next_small_pomalystBtn {
    background: #007482;
}

.btn_Next_small_skyBlueBtn{
    background: linear-gradient(180deg, #9CF 0%, #9CF 13.54%, #9CF 26.04%, #9CF 52.6%, #9CF 74.48%, #9CF 88.54%, #9CF 100%);
}

.btn_Next_small_redBtn{
    background: #ef3a42;
}
.btn_Submit_pom{
    background-image: url(../../assets/images/program/pomalyst/btn_Submit.png);
    width:112px;
    
}

.btn_Submit_len{
    background-image: url(../../assets/images/program/lenalidomide/btn_Submit.png);
    width:112px;
    
}

.btn_Submit_thal{
    background-image: url(../../assets/images/program/thalomid/btn_Submit.png);
    width:112px;
    
}
// Enable btn
.btn_Submit_Enable_pom{
    background-image: url(../../assets/images/program/pomalyst/btn_Submit_Enable.png);
    width:112px;
    
}.btn_Submit_Enable_len{
    background-image: url(../../assets/images/program/lenalidomide/btn_Submit_Enable.png);
    width:112px;
    
}.btn_Submit_Enable_thal{
    background-image: url(../../assets/images/program/thalomid/btn_Submit_Enable.png);
    width:112px;
    
}
// Enable es btn
.btn_Submit_Enable_pom_es{
    background-image: url(../../assets/images/program/es/pomalyst/btn_Submit_Enable.png);
    width:112px;
    
}

.btn_Submit_Enable_len_es{
    background-image: url(../../assets/images/program/es/lenalidomide/btn_Submit_Enable.png);
    width:112px;
    
}

.btn_Submit_Enable_thal_es{
    background-image: url(../../assets/images/program/es/thalomid/btn_Submit_Enable.png);
    width:112px;
    
}

.btn_Start_Over_len{
    background-image: url(../../assets/images/program/lenalidomide/btn_Start_Over.png);
    width:137px;
    

}

.btn_Start_Over_thal{
    background-image: url(../../assets/images/program/thalomid/btn_Start_Over.png);
    width:137px;
    

}

.btn_Start_Over_pom{
    background-image: url(../../assets/images/program/pomalyst/btn_Start_Over.png);
    width:137px;
    

}

.btn_Submit_pom_es{
    background-image: url(../../assets/images/program/es/pomalyst/btn_Submit.png);
    width:112px;
    
}

.btn_Submit_len_es{
    background-image: url(../../assets/images/program/es/lenalidomide/btn_Submit.png);
    width:112px;
    
}

.btn_Submit_thal_es{
    background-image: url(../../assets/images/program/es/thalomid/btn_Submit.png);
    width:112px;
    
}

.btn_Start_Over_len_es{
    background-image: url(../../assets/images/program/es/lenalidomide/btn_Start_Over.png);
    width:238px;
    

}

.btn_Start_Over_thal_es{
    background-image: url(../../assets/images/program/es/thalomid/btn_Start_Over.png);
    width:238px;
   
    

}

.btn_Start_Over_pom_es{
    background-image: url(../../assets/images/program/es/pomalyst/btn_Start_Over.png);
    width:238px;
    
    

}

.programlogo {
    @include iconStyle;
    width: 250px;
    height: 41px;
    padding: 5px 0px;
    align-self: flex-end;
}
.spanishBtn_rems_program_lenalidomide{
    background-image: url(../../assets/images/program/lenalidomide/btn_Spanish.png);
    width: 137px;
    height: 55px;
    background-repeat: no-repeat;
    border: 0;
    background-color: transparent;
}
.spanishBtn_rems_program_pomalyst{
    background-image: url(../../assets/images/program/pomalyst/btn_Spanish.png);
    width: 137px;
    height: 55px;
    background-repeat: no-repeat;
    border: 0;
    background-color: transparent;
}
.spanishBtn_rems_program_thalomid{
    background-image: url(../../assets/images/program/thalomid/btn_Spanish.png);
    width: 137px;
    height: 55px;
    background-repeat: no-repeat;
    border: 0;
    background-color: transparent;
}
.headerDivider_rems_program_lenalidomide{
    border-bottom: 2px solid #fec110;
}
.headerDivider_rems_program_pomalyst{
    border-bottom: 2px solid #007482;
}
.headerDivider_rems_program_thalomid{
    border-bottom: 2px solid #F13945;
}

/* styles based on program*/
.headerDivider {
    border-bottom: 2px solid;
}
.rems_program_thalomid {
    .programlogo {
        background-image: url(../../assets/images/img_STEPS_transparent.png);
    }
    .headerDivider {
        border-bottom: 2px solid #f13945;
    }
    
}

.rems_program_lenalidomide {
    .programlogo {
        background-image: url(../../assets/images/img_RevAssist_transparent.png);
    }

    .headerDivider {
        border-bottom: 2px solid #fec110;
    }
   
}

.rems_program_pomalyst {
    .programlogo {
        background-image: url(../../assets/images/Image_PomalidomideREMS_Transparent.png);
    }

    .headerDivider {
        border-bottom: 2px solid #007482;
    }
   
}

.risk_warning{
    font-size: 12px;
}