.layout {
    width: 100vw;
    height: calc(100vh - 8px);
    background-image: url('../../assets/images/img_global_bg.png');
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
    font-size: 12px;
    color: #193878;
    padding-top: 8px;
    td{
        border: none !important;
    }
}
.layout td {
    border: none !important;
    padding: 0 !important;

}

.layout td {
    border: none !important;
    padding: 0 !important;

}
.help {
    td{
        border: none;
    }
    span.icons {
        display: inline-block;
        text-align: center;
        background-repeat: no-repeat;
        background-color: transparent;
        background-position: center center;
        vertical-align: middle;
        float: left;
        padding-left: 12px;
    }

    .logo {
        background-image: url(../../assets/images/img_bms_logo.png);
        width: 256px;
        height: 35px;
        padding: 15px 0px;
    }


    .buffer {
        padding: 2px;
        font-size: 8px;
        line-height: 8px;
        clear: both;
    }

    .headerDivider {
        border-bottom: 2px solid #fec110;
    }

    .title {
        font-size: 18px;
        font-weight: bold;
        padding: 10px 0px;
        float: right;
    }

    ul {
        padding-left: 15px;
    }
   

    ul,
    ol {
        padding-top: 0px;
        padding-bottom: 0px;
        margin: 0 0 0 2px;
    }

    ul li {
        list-style: disc;
    }

    ul li,
    ol li {
        padding-top: 3px;
        padding-left: 5px;
    }
}
.headerDivider_rems_program_lenalidomide{
    border-bottom: 2px solid #fec110;
    padding-top: 10px;
}
.headerDivider_rems_program_pomalyst{
    border-bottom: 2px solid #007482;
    padding-top: 10px;
}
.headerDivider_rems_program_thalomid{
    border-bottom: 2px solid #F13945;
    padding-top: 10px;
}
.headerDividerRems{
    border-bottom: 2px solid;
    padding-top: 10px;
}

@mixin iconStyle {
    display: inline-block;
    text-align: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;
    vertical-align: middle;
}
.rems_program_lenalidomide {
 
    background-image: url(../../assets/images/img_RevAssist_transparent.png);
    @include iconStyle;
    width: 235px;
    height: 41px;
    padding-left: 2px;
    position: relative;
    top: 7px;
    
}
.imageClass{
    @include iconStyle;
    width: 235px;
    height: 41px;
    padding-left: 2px;
    position: relative;
    top: 7px;
}
.rems_program_pomalyst {
    @include iconStyle;
    width: 256px;
    height: 35px;
    padding-left: 2px;
    background-image: url(../../assets/images/Image_PomalidomideREMS_Transparent.png);
    position: relative;
    top: 7px;
    right: 10px;
}
.rems_program_thalomid {
    @include iconStyle;
    width: 256px;
    height: 35px;
    padding-left: 2px;
    background-image: url(../../assets/images/img_STEPS_transparent.png);
    position: relative;
    top: 7px;
    right: 10px;
}
.headerImgCls{
    @include iconStyle;
    width: 256px;
    height: 35px;
    padding-left: 2px;
    position: relative;
    top: 7px;
    right: 10px;
}

