.loginContentContainer {
    background-color: #7ba5c4;
    background-image: url('../../../assets/images/prescriber_login_bg.png');
    background-repeat: no-repeat;
    background-position: top left;
    width: 815px;
    border-radius: 10px;
    max-height: max-content;
    margin-top: 5px;
    display: flex;   
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-top: 70px;
    padding-bottom: 10px;
}
.loginForm {
    border: 2px solid #5480a2;
    border-radius: 10px;
    padding: 10px;
    width: 387px;
    margin: 5px 0px;
    background: -webkit-gradient(linear, left top, left bottom, from(#c9e4f8), to(#e5f2fd));
}
.commonStyles {
    font-family: Verdana, Arial, Helvetica, Clean, Sans-Serif;
}
.heading1 {    
    font-weight: bold;
    font-size: 16px;
    color: #193878;  
    padding: 10px 0px;  
    text-align: center;
}
.submitbutton{
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #193878;
    display: flex;
    justify-content: center;
    align-items: center;
}
.passwordContainer{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    input::-ms-reveal,
    input::-ms-clear {
      display: none;
    }
}

.pswdIcon{
    background: none;
    font-size: 18px;
    border: unset;
    cursor: pointer;
    position: absolute;
    left: 90%;
    padding-top: 3px;
}

.psdIc{
    background: none;
    font-size: 18px;
    border: unset;
    position: absolute;
    left: 51%;
    top: 6%;
    cursor: pointer;
}
.passwordContainer input[type='password'],
.passwordContainer input[type='text'] {
    box-sizing: border-box;
    width: 291px;
    padding-inline-end: 25px;
}
.logincontent {
    font-size: 12px;
    color: #193878;    
    width: 20%;
    text-align: right;
    padding-right: 10px;
}
.loginValue{
    width: 73%;
}
.toLogin {
    font-size: 12px;
    color: #193878;
    padding: 0px 16px 0px 0px;
}
.label {
    width: 80px;
    margin-right: 10px;
}
.formGroup {
    display: flex;
    margin: 15px 0px;
    width: 100%;
    position: relative;
}
.forgotPassword {
    color: #2b88b4;
    padding-right: 10px;
    text-decoration: none;
    cursor: pointer;
    font-size: 12px;
    margin-right: 14px;
    background: url(../../../assets/images/btn_arrow_right.png) no-repeat center right;
}
.buttonWrapper {
    margin: 5px 0px;
    display: flex;
    column-gap: 5px;
    &.buttonStyles {
        margin-left: 30px;
    }
}

input::-ms-reveal{
    display:none;
}