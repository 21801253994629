.popupDiv {
    position: absolute;
    z-index: 10;
    transform: translateY(-114px);
    color: #193878;
}

.whiteInfoContainer {
    background-color: #FFFFFF;
    border: 2px solid #7690AB;
}

.pad_T {
    padding-top: 10px;
}

.pad_LR {
    padding-left: 10px;
    padding-right: 10px;
}

.slimPad_B {
    padding-bottom: 5px;
}

.contentDivider {
    border-top: 1px solid #C0C0C0;
}

.bigPad {
    padding: 15px;
}

.pad_B {
    padding-bottom: 10px;
}

.right {
    float: right;
}

.bold {
    font-weight: bold;
}

.left {
    float: left;
}

.buffer {
    padding: 2px;
    font-size: 8px;
    line-height: 8px;
}

.clear {
    clear: both;
}

.icon {
    display: inline-block;
    text-align: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;
    vertical-align: middle;
}

.popupContinueBtnSml {
    background-image: url('../../../assets/images/btn_Continue_small.png');
    width: 131px;
    height: 39px;
}

.shortMarginTop{
    margin-top: 7px;
}