.filterOptions {
    :global {
        display: flex;
        justify-content: center;
        form{
            transform: translateX(-32px);
        }
        .filterRowOne {
            display: flex;
            align-items: center;
            gap: 5px;
            p {
                font-style: italic;
                font-size: 12px;
                color: #193878;
                padding-right: 8px;
            }
            .findBtn {
                transform: translateY(3px);
                cursor: pointer;
            }
        }
        .filterRowTwo {
            .resetBtn {
                cursor: pointer;
            }
        }
    }
}
